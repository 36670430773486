import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {SettingProvider} from "./providers/SettingsProvider";
import {UIProvider} from "./providers/UIProvider";
import {HomeProvider} from "./providers/HomeProvider";
import {BaseProvider} from "./providers/BaseProvider";
import Wrapper from "./Wrapper";
import {AudioProvider} from "./providers/AudioProvider";

ReactDOM.render(
  <React.StrictMode>
    <AudioProvider>
      <UIProvider>
        <BaseProvider>
          <SettingProvider>
            <HomeProvider>
              <Wrapper/>
            </HomeProvider>
          </SettingProvider>
        </BaseProvider>
      </UIProvider>
    </AudioProvider>
  </React.StrictMode>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
