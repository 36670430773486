import * as React from "react";

const PatternIcon = (
  {
    fill = '#fff',
  }) => {
  return (
    <svg id="Paw_pattern_icon" data-name="Paw pattern icon" xmlns="http://www.w3.org/2000/svg" width="52" height="52"
         viewBox="0 0 52 52">
      <path id="pattern"
            fill={fill}
            d="M52,7.94V10L10,52H7.94A8,8,0,0,1,0,44.06V42L42,0h2.06A8,8,0,0,1,52,7.94ZM7.94,0A8,8,0,0,0,0,7.94V22L22,0ZM30,52H44.06A8,8,0,0,0,52,44.06V30Z"
            transform="translate(0)"/>
    </svg>
  )
}

export default PatternIcon;
