import {CHOICES, CHOICE_PAPER, CHOICE_ROCK, CHOICE_SCISSORS} from "../constant";

export const pickRandomChoice = () => {
  return CHOICES[Math.floor(Math.random() * CHOICES.length)];
}

export const favoritePick = (picks) => {
  let favorite = {};
  const rockPicks = picks?.[CHOICE_ROCK] ?? 0
  const paperPicks = picks?.[CHOICE_PAPER] ?? 0
  const scissorsPicks = picks?.[CHOICE_SCISSORS] ?? 0;
  const totalPicks = rockPicks + paperPicks + scissorsPicks;

  if (totalPicks > 0) {
    if (rockPicks > paperPicks) {
      if (scissorsPicks > rockPicks) {
        // scissors
        favorite = {pick: CHOICE_SCISSORS, count: scissorsPicks, percent: Math.round(scissorsPicks / totalPicks * 100)};
      } else {
        // rock
        favorite = {pick: CHOICE_ROCK, count: rockPicks, percent: Math.round(rockPicks / totalPicks * 100)};
      }
    } else {
      if (scissorsPicks > paperPicks) {
        // scissors
        favorite = {pick: CHOICE_SCISSORS, count: scissorsPicks, percent: Math.round(scissorsPicks / totalPicks * 100)};
      } else {
        // paper
        favorite = {pick: CHOICE_PAPER, count: paperPicks, percent: Math.round(paperPicks / totalPicks * 100)};
      }
    }
  }

  return favorite;
}

export const getParamFromUrl = key => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const param = urlParams.get(key)?.replace(/ /g, '+');
  return param ? decodeURIComponent(param) : undefined;
}

export const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};
