import * as React from "react";

import clsx from "clsx";
import style from "./PrivateEndScreen.module.scss";
import {useGameContext} from "../../providers/GameProvider";
import useScale from "../../hooks/useScale";
import treatIcon from "../../assets/images/treat.png";
import {ButtonV2} from "../Home";
import {useBaseContext} from "../../providers/BaseProvider";
import closeIcon from "../../assets/images/close.png";
import trophyIcon from '../../assets/images/trophy.svg';
import {useUIContext} from "../../providers/UIProvider";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const PrivateEndScreen = () => {
  const [ref] = useScale();
  const {clickReadyForMatch, leave, opponent, me, opponentFaceDirectory, meFaceDirectory} = useGameContext();
  const opponentReadyForMatch = React.useMemo(() => opponent?.readyForMatch, [opponent?.readyForMatch]);
  const meReadyForMatch = React.useMemo(() => me?.readyForMatch, [me?.readyForMatch]);
  const {formattedCoin} = useBaseContext();
  const {setVisiblePrivateEnd} = useUIContext();
  const {playSound} = useAudioContext();
  const {t} = useTranslation();

  React.useEffect(() => {
    setVisiblePrivateEnd(true);

    return () => {
      setVisiblePrivateEnd(false);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.Toolbar)}>
        <div className={clsx(style.Left)}>
          <div className={clsx(style.CurrencyWrapper)}>
            <img src={treatIcon} className={clsx(style.TreatIcon)} alt="treat icon"/>

            <span>{formattedCoin}</span>
          </div>
        </div>

        <div className={clsx(style.SettingsWrapper)}>
          <img src={closeIcon} alt="close icon" onClick={() => {
            playSound('click');
            leave({forced: false, playNew: false});
          }}/>
        </div>
      </div>

      <div className={clsx(style.Title)}>
        <p>{t("Final")}</p>
        <p>{t("Results")}</p>
      </div>

      <div className={clsx(style.Body)}>
        <div className={clsx(style.Preview)}>
          {
            Boolean(me?.breed) ?
              <img
                className={clsx(style.PreviewImg)}
                style={{background: me?.color}}
                src={`/avatar/breed/${me.breed}.png`} alt="breed icon"/> :
              <img
                className={clsx(style.PreviewImg)}
                src={`/avatar/breed/2.png`} alt="breed icon"/>
          }
          {
            Boolean(me?.hat) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/hat/${me.hat}.png`} alt="hat icon"/>
          }
          {
            Boolean(meFaceDirectory) && Boolean(me?.face) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/face/${meFaceDirectory}/${me.face}.png`} alt="face icon"/>
          }
          {
            Boolean(me?.necktie) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/accessory/${me.necktie}.png`} alt="accessory icon"/>
          }
        </div>

        <div className={clsx(style.Info)}>
          <div className={clsx(style.Name)}>
            {t("You")}
          </div>

          <div className={clsx(style.History)}>
            <div className={clsx(style.TrophyWrapper)}>
              <img src={trophyIcon} alt="trophy icon"/>
              <span>{me?.totalWins}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={clsx(style.Body)}>
        <div className={clsx(style.Preview)}>
          {
            Boolean(opponent?.breed) ?
              <img
                className={clsx(style.PreviewImg)}
                style={{background: opponent?.color}}
                src={`/avatar/breed/${opponent.breed}.png`} alt="breed icon"/> :
              <img
                className={clsx(style.PreviewImg)}
                src={`/avatar/breed/2.png`} alt="breed icon"/>
          }
          {
            Boolean(opponent?.hat) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/hat/${opponent.hat}.png`} alt="hat icon"/>
          }
          {
            Boolean(opponentFaceDirectory) && Boolean(opponent?.face) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/face/${opponentFaceDirectory}/${opponent.face}.png`} alt="face icon"/>
          }
          {
            Boolean(opponent?.necktie) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/accessory/${opponent.necktie}.png`} alt="accessory icon"/>
          }
        </div>

        <div className={clsx(style.Info)}>
          <div className={clsx(style.Name)}>
            {opponent?.name}
          </div>

          <div className={clsx(style.History)}>
            <div className={clsx(style.TrophyWrapper)}>
              <img src={trophyIcon} alt="trophy icon"/>
              <span>{opponent?.totalWins}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={clsx(style.Footer)}>
        {
          meReadyForMatch ?
            <>
              <p>{t("Waiting for opponent")}</p>
              <p>{t("to join")}</p>
            </>
            :
            <>
              {opponentReadyForMatch && <p>{t("[playername] would like a rematch!", {playername: opponent?.name})}</p>}
              <ButtonV2 theme='white' label={t('Rematch')} onClick={() => {
                playSound('click');
                clickReadyForMatch('private')();
              }}/>
            </>
        }
      </div>
    </div>
  )
}

export default PrivateEndScreen;
