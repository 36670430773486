import React from "react";
import clsx from "clsx";
import style from "./PauseScreen.module.scss";
import {useGameContext} from "../../providers/GameProvider";
import {useParams} from "react-router-dom";
import {ButtonV2} from "../Home";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const PauseScreen = () => {
  const {session} = useParams();
  const {
    resume, pausedBy, leave,
  } = useGameContext();
  const {t} = useTranslation();
  const {playSound} = useAudioContext();
  const pausedByMe = (pausedBy === session);

  return (
    <div className={clsx(style.Wrapper)}>
      <div className={clsx(style.Title)}>
        <p>{t("Paused")}</p>
      </div>

      <div className={clsx((style.Body))}>
        <div className={clsx(style.Header)}>
          <p>
            {
              pausedByMe ? t("You paused the game") : t("Opponent paused the game")
            }
          </p>
        </div>
        {
          pausedByMe &&
          <div className={clsx(style.ButtonWrapper)}>
            <ButtonV2 label={t('Resume')} theme={'white'} onClick={() => {
              playSound('click');
              resume();
            }}/>
            <ButtonV2 label={t('End Match')} theme={'dark-brown'} onClick={() => {
              playSound('click');
              leave({forced: true, playNew: false});
            }}/>
          </div>
        }
      </div>
    </div>
  )
}

export default PauseScreen;
