import * as React from "react";
import {useParams} from "react-router-dom";
import {useUIContext} from "./UIProvider";
import {CHOICE_PAPER, CHOICE_ROCK, CHOICE_SCISSORS, CHOICES, GAME_MODE, faceDirectoryMap} from "../constant";
import {favoritePick} from "../utils";
import {useEffect} from "react";
import {useSettingContext} from "./SettingsProvider";
import {useHomeContext} from "./HomeProvider";
import {useBaseContext} from "./BaseProvider";
import {useAudioContext} from "./AudioProvider";

const GameContext = React.createContext(null);

export const GameProvider = ({children}) => {
  const {client, joinRoomById, webAddress, startGame} = useHomeContext();
  const {
    publicName: name,
    breed,
    face,
    necktie,
    hat,
    mitt,
    toe,
    color,
    pattern,
    patternColor,
  } = useSettingContext();
  const {playSound} = useAudioContext();
  const {setCoinDeep, coin} = useBaseContext();
  const coinRef = React.useRef(coin);
  React.useEffect(() => {
    coinRef.current = coin;
  }, [coin]);
  const {setLoading, setVisibleLobby} = useUIContext();
  const {id, session, mode} = useParams();
  const [visibleMode, _setVisibleMode] = React.useState(null); // playing, round-between, end-game
  const visibleModeRef = React.useRef(visibleMode);
  const [connected, setConnected] = React.useState(false);
  const [joined, setJoined] = React.useState(false);
  const {loaded, faceInitialized} = useSettingContext();
  const setVisibleMode = (val) => {
    visibleModeRef.current = val;
    _setVisibleMode(val);
  };
  const [count, _setCount] = React.useState(0);
  const countRef = React.useRef(count);
  const setCount = v => {
    _setCount(v);
    countRef.current = v;
  }

  const [countdown, _setCountdown] = React.useState(null);
  const countdownRef = React.useRef(countdown);
  const setCountdown = (val) => {
    _setCountdown(val);
    countdownRef.current = val;
  };
  const [winnerId, _setWinnerId] = React.useState(null);
  const winnerIdRef = React.useRef(winnerId);
  const setWinnerId = v => {
    _setWinnerId(v);
    winnerIdRef.current = v;
  }

  useEffect(() => {
    if (loaded && id && faceInitialized) {
      if (["-1", undefined, null].includes(session)) {
        if (mode === GAME_MODE.PRIVATE) {
          joinToRoom().then();
        }
      } else {
        reconnect().then();
      }
    }
  }, [loaded, faceInitialized]);

  React.useEffect(() => {
    roomRef?.current?.send("signal", {
      type: "settings",
      payload: {name, breed, face, necktie, hat, mitt, toe, color, pattern, patternColor},
    });
  }, [name, breed, face, necktie, hat, mitt, toe, color, pattern, patternColor]);


  const joinToRoom = async () => {
    if (joined) return;
    try {
      setLoading(true);
      const room = await joinRoomById(id);
      setJoined(true);
      const mySession = room.sessionId;
      if (mySession) {
        window.location.href = `${webAddress()}/play-v2/private/${id}/${mySession}`;
      }
    } catch (e) {
      if (e?.code?.toString() === "4212") {
        if (e.toString().includes('locked')) {
          // todo
        } else if (e.toString().includes('not found')) {
          // todo
        }
      }
      console.log("join by id error", e);
      window.location.href = "/home";
    } finally {
      setLoading(false);
    }
  }

  let roomRef = React.useRef(null);

  const reconnect = async () => {
    if (connected === true) return;
    if (id && session && (session !== "-1")) {
      try {
        setLoading(true);
        roomRef.current = await client.reconnect(id, session);
        setConnected(true);
        roomListener(roomRef.current);
      } catch (e) {
        console.error("socket reconnect error", e);
        window.location.href = "/home";
      } finally {
        setLoading(false);
      }
    }
  };
  const getPlayers = () => {
    const playersArr = [];
    if (roomRef?.current?.state?.players?.size > 0) {
      roomRef.current.state.players.forEach((player, playerId) => {
        const favorite = favoritePick(
          {
            [CHOICE_SCISSORS]: player[CHOICE_SCISSORS],
            [CHOICE_PAPER]: player[CHOICE_PAPER],
            [CHOICE_ROCK]: player[CHOICE_ROCK],
          });
        playersArr.push({
          ...player,
          name: (playerId === session) ? "You" : player["name"],
          id: playerId,
          left: player.left ?? false,
          winStreaks: player.winStreaks ?? 0,
          favorite: favorite?.pick,
          favoritePercent: favorite?.percent,
          joinedAt: player.joinedAt,
        });
      });
    }
    return playersArr;
  };
  const players = getPlayers();
  const flattenedPlayers = JSON.stringify(players);

  const host = React.useMemo(() => {
    const temp = JSON.parse(JSON.stringify(players ?? []));
    temp?.sort((a, b) => {
      const timeA = new Date(a.joinedAt).getTime();
      const timeB = new Date(b.joinedAt).getTime();
      return timeA - timeB > 0 ? 1 : -1;
    });

    return temp?.length > 0 ? temp[0] : null;
  }, [flattenedPlayers]);

  const getChoices = () => {
    let ret = [];
    roomRef?.current?.state?.round?.playerChoices?.forEach((it, playerId) => {
      ret.push({
        id: playerId,
        choice: it,
      });
    });
    return ret;
  };

  const choices = getChoices();
  const flattenedChoices = JSON.stringify(choices);

  const getWins = () => {
    let ret = [];
    roomRef?.current?.state?.playerGameStats?.forEach((it, playerId) => {
      ret.push({
        id: playerId,
        wins: it.wins,
      });
    });
    return ret;
  };

  const wins = getWins();
  const flattenedWins = JSON.stringify(wins);

  const paused = React.useMemo(() => {
    return roomRef?.current?.state?.paused;
  }, [roomRef?.current?.state?.paused, countRef.current])

  const pausedBy = React.useMemo(() => {
    return roomRef?.current?.state?.pausedBy;
  }, [roomRef?.current?.state?.pausedBy, countRef.current]);

  const roomListener = (room) => {
    room.state.listen("status", (value) => {
      switch (value) {
        case "waiting":
          setVisibleMode("waiting");
          break;
        case "starting":
          setVisibleMode("starting");
          break;
        case "playing":
          setVisibleMode("playing");
          break;
        case "reviewing_round_winner":
          setVisibleMode("reviewing_round_winner");
          break;
        case "reviewing_draw":
          setVisibleMode("reviewing_draw");
          break;
        case "reviewing_game_winner":
          setVisibleMode("reviewing_game_winner");
          break;
        default:
          console.log("status type not registered");
      }
    });

    room.onLeave((code) => {
      if (code?.toString() !== "1000") {
        window.location.href = '/home';
      }
    });

    room.onStateChange(() => {
      setCount(countRef.current + 1);
    });

    room.onStateChange.once(() => {
      setCount(countRef.current + 1);
    });

    room.onMessage("signal", data => {
      const {type, payload} = data;

      switch (type) {
        case "timer":
          setCountdown(payload?.remaining);
          break;
        case "roundWinner":
          setWinnerId(payload?.playerId);
          break;
        case "gameWinner":
          setWinnerId(payload?.playerId);
          if (payload?.playerId === session) {
            setCoinDeep(coinRef.current + 75);
            playSound("game-win");
          } else { // todo enforce the logic
            setCoinDeep(coinRef.current + 25);
            playSound("game-loss");
          }
          break;
        case "favorite":
          if (session === payload?.playerId) {
            localStorage.setItem("picks", JSON.stringify(payload));
          }
          break;
        case "opponent-left":
          setVisibleMode('alone');
          break;
        default:
          console.log("signal type not registered");
      }
    });
  };

  const pickChoice = choice => {
    if (
      ableToPick
      && CHOICES.includes(choice)
    ) {
      roomRef?.current?.send("signal", {type: "pick", payload: {choice}});
    }
  }

  const winner = React.useMemo(() => {
    return players.find(it => it.id === winnerIdRef.current);
  }, [winnerIdRef.current, flattenedPlayers])

  const isPlaying = React.useMemo(() => {
    return ["playing"].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  const visibleLobby = React.useMemo(() => {
    return ["waiting", "starting"].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  React.useEffect(() => {
    setVisibleLobby(visibleLobby);
  }, [visibleLobby]);

  const visibleRoundWinner = React.useMemo(() => {
    return ["reviewing_round_winner"].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  const visibleDraw = React.useMemo(() => {
    return ["reviewing_draw"].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  const visibleGameWinner = React.useMemo(() => {
    return ["reviewing_game_winner"].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  const visibleAlone = React.useMemo(() => {
    return ['alone'].includes(visibleModeRef.current);
  }, [visibleModeRef.current]);

  const visiblePlayground = React.useMemo(() => {
    return isPlaying || visibleRoundWinner || visibleDraw;
  }, [isPlaying, visibleRoundWinner, visibleDraw]);

  React.useEffect(() => {
    if (visiblePlayground) {
      playSound("match-start");
    }
  }, [visiblePlayground]);

  const roundEnded = React.useMemo(() => {
    return visibleRoundWinner || visibleDraw; 
  }, [visibleRoundWinner, visibleDraw]);

  React.useEffect(() => {
    if (roundEnded) setCountdown(null);
  }, [roundEnded]);

  const ableToPick = React.useMemo(() => {
    return isPlaying && countdown > 0;
  }, [isPlaying, countdown]);

  const isPrivate = mode === GAME_MODE.PRIVATE;

  const clickReadyForMatch  = (mode) => () => {
    if (window.parent && window.parent.postMessage) {
      const eventValue = mode === 'public' ? 3 : mode === 'private' ? 4 : null;
      if (eventValue) {
        window.parent.postMessage(JSON.stringify({eventType: "gameEvent", key: "start", value: eventValue}), "*");
      }
    }
    roomRef?.current?.send("signal", {type: "ready-for-match"});
  }

  const leave = (
    {
      forced = false,
      playNew = false,
      privateGame = false,
    }) => {
    if (forced) {
      localStorage.setItem("win-streaks", "0");
    }
    if (roomRef?.current) {
      roomRef?.current?.leave()
        .finally(() => {
          if (playNew) {
            if (privateGame) window.location.href = "/home?newGame=private";
            else {
              startGame('public');
              // window.location.href = "/home?newGame=public";
            }
          } else {
            window.location.href = "/home";
          }
        });
    }
  }

  const pause = () => {
    roomRef?.current?.send("signal", {type: "pause"});
  }

  const resume = () => {
    roomRef?.current?.send("signal", {type: "resume"});
  }

  const [me, setMe] = React.useState(null);
  const [opponent, setOpponent] = React.useState(null);
  React.useEffect(() => {
    const opponentInfo = players?.find(it => it.id !== session) ?? {};
    const opponentChoice = choices?.find(it => it.id !== session) ?? {};
    const opponentWins = wins?.find(it => it.id !== session) ?? {};
    const myInfo = players?.find(it => it.id === session) ?? {};
    const myChoice = choices?.find(it => it.id === session) ?? {};
    const myWins = wins?.find(it => it.id === session) ?? {};

    setMe({...myInfo, ...myChoice, ...myWins});
    setOpponent({...opponentInfo, ...opponentChoice, ...opponentWins,});
  }, [flattenedPlayers, session, flattenedChoices, flattenedWins]);
  React.useEffect(() => {
    if (![undefined, null, ""].includes(me?.winStreaks)) {
      localStorage.setItem("win-streaks", me?.winStreaks);
    }
  }, [me?.winStreaks]);

  const opponentFaceDirectory = React.useMemo(() => faceDirectoryMap[parseInt(opponent?.breed) - 1], [opponent?.breed]);
  const meFaceDirectory = React.useMemo(() => faceDirectoryMap[parseInt(me?.breed) - 1], [me?.breed]);

  const providerValue = {
    players,
    choices,
    pickChoice,
    ableToPick,
    winner,
    countdown,
    connected: !!roomRef?.current,
    visibleRoundWinner,
    visibleGameWinner,
    visiblePlayground,
    visibleLobby,
    visibleAlone,
    visibleDraw,
    roundEnded,
    clickReadyForMatch,
    wins,
    leave,
    isPrivate,
    host,
    paused,
    pause,
    resume,
    pausedBy,
    me,
    opponent,
    isPlaying,
    visibleMode,
    opponentFaceDirectory,
    meFaceDirectory,
  };

  return (
    <GameContext.Provider value={providerValue}>
      {children}
    </GameContext.Provider>
  )
};

export const useGameContext = () => {
  const context = React.useContext(GameContext);
  if (!context) {
    throw new Error("useGameContext must be used within GameProvider");
  }
  return context;
};
