import * as React from "react";

const FaceIcon = (
  {
    fill="#A96642",
  }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="39" viewBox="0 0 57 39" fill="none">
      <path d="M33.3302 15.63L41.0902 5.63C41.4903 5.1146 41.7379 4.49735 41.8049 3.84834C41.8719 3.19932 41.7556 2.54452 41.4692 1.95825C41.1829 1.37199 40.7379 0.877733 40.1848 0.531593C39.6317 0.185454 38.9927 0.00128907 38.3402 0H17.7702C17.1178 0.00128907 16.4787 0.185454 15.9256 0.531593C15.3726 0.877733 14.9276 1.37199 14.6412 1.95825C14.3549 2.54452 14.2386 3.19932 14.3056 3.84834C14.3725 4.49735 14.6201 5.1146 15.0202 5.63L22.7802 15.63C23.407 16.4303 24.2076 17.0776 25.1215 17.5227C26.0355 17.9678 27.0387 18.1991 28.0552 18.1991C29.0718 18.1991 30.075 17.9678 30.9889 17.5227C31.9028 17.0776 32.7035 16.4303 33.3302 15.63Z" fill={fill}/>
      <path d="M39.7499 38.6396C31.3599 38.2296 24.9199 30.9896 24.9199 22.6396V0.639648H31.2199V22.7697C31.19 25.1714 32.0672 27.496 33.6762 29.2793C35.2853 31.0626 37.5078 32.1733 39.8999 32.3896C41.1729 32.4744 42.4498 32.2972 43.6516 31.869C44.8534 31.4407 45.9547 30.7706 46.8872 29.8999C47.8198 29.0292 48.5638 27.9765 49.0734 26.8068C49.583 25.6372 49.8473 24.3755 49.8499 23.0996V21.8896C49.8499 21.3751 50.0543 20.8817 50.4182 20.5179C50.782 20.154 51.2754 19.9497 51.7899 19.9497H54.2099C54.7245 19.9497 55.2179 20.154 55.5817 20.5179C55.9455 20.8817 56.1499 21.3751 56.1499 21.8896V23.0996C56.1403 25.2111 55.703 27.2986 54.8644 29.2364C54.0258 31.1741 52.8033 32.9219 51.2707 34.3742C49.738 35.8265 47.927 36.9532 45.947 37.6863C43.9669 38.4194 41.8588 38.7437 39.7499 38.6396Z" fill={fill}/>
      <path d="M14.82 38.6398C6.44002 38.2298 2.60445e-05 30.9898 2.60445e-05 22.6398V21.8898C-0.00129936 21.6347 0.0479751 21.3818 0.145 21.1459C0.242025 20.9099 0.384878 20.6955 0.565288 20.5151C0.745698 20.3347 0.960086 20.1918 1.19606 20.0948C1.43203 19.9978 1.68489 19.9485 1.94003 19.9498H4.33003C4.58563 19.9485 4.83898 19.9977 5.07551 20.0946C5.31204 20.1915 5.52707 20.3342 5.70828 20.5145C5.88948 20.6948 6.03329 20.9091 6.1314 21.1451C6.22952 21.3811 6.28003 21.6342 6.28003 21.8898V22.7698C6.24757 25.1706 7.12254 27.4952 8.72996 29.2788C10.3374 31.0623 12.5588 32.1734 14.95 32.3898C16.223 32.4746 17.4999 32.2974 18.7017 31.8692C19.9035 31.4409 21.0048 30.7708 21.9373 29.9001C22.8698 29.0294 23.6139 27.9767 24.1235 26.807C24.6331 25.6374 24.8974 24.3757 24.9 23.0998V0.589844H31.2V23.0498C31.197 25.1637 30.7653 27.255 29.931 29.1972C29.0966 31.1394 27.8769 32.8922 26.3457 34.3496C24.8145 35.8069 23.0036 36.9385 21.0226 37.6759C19.0415 38.4134 16.9314 38.7413 14.82 38.6398Z" fill={fill}/>
    </svg>
  )
}

export default FaceIcon;
