import * as React from "react";

import clsx from "clsx";
import style from "./EndScreen.module.scss";
import {useGameContext} from "../../providers/GameProvider";
import {useParams} from "react-router-dom";
import useScale from "../../hooks/useScale";
import treatIcon from "../../assets/images/treat.png";
import {ButtonV2} from "../Home";
import {useBaseContext} from "../../providers/BaseProvider";
import closeIcon from "../../assets/images/close.png";
import {faceDirectoryMap} from "../../constant";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const EndScreen = () => {
  const [ref] = useScale();
  const {t} = useTranslation();
  const {session} = useParams();
  const {winner, clickReadyForMatch, players, leave, opponent, me} = useGameContext();
  const {formattedCoin} = useBaseContext();
  const amIWinner = React.useMemo(() => session === winner?.id, [session]);
  const opponentLeft = React.useMemo(() => players?.length < 2 || opponent?.left, [players, opponent?.left]);
  const opponentReadyForRematch = React.useMemo(() => opponent?.readyForMatch, [opponent?.readyForMatch]);
  const meReadyForRematch = React.useMemo(() => me?.readyForMatch, [me?.readyForMatch]);
  const {playSound} = useAudioContext();
  const winnerFull = React.useMemo(() => {
    if (winner?.id) {
      if (me?.id === winner?.id) return me;
      else if (opponent?.id === winner?.id) return opponent;
    }
    return null;
  }, [winner?.id]);
  const winnerFullFaceDirectory = React.useMemo(() => faceDirectoryMap[parseInt(winnerFull?.breed) - 1], [winnerFull?.breed]);

  return (
    <div ref={ref} className={clsx(style.Wrapper)}>
      <div className={clsx(style.Toolbar)}>
        <div className={clsx(style.Left)}>
          <div className={clsx(style.CurrencyWrapper)}>
            <img src={treatIcon} className={clsx(style.TreatIcon)} alt="treat icon"/>

            <span>{formattedCoin}</span>
          </div>
        </div>

        <div className={clsx(style.SettingsWrapper)}>
          <img src={closeIcon} alt="close icon" onClick={() => {
            playSound('click');
            leave({forced: false, playNew: false});
          }}/>
        </div>
      </div>

      <div className={clsx(style.Title)}>
        {
          amIWinner ?
            <>
              <p>{t("You won the match")}</p>
              {/*<p>You won</p>
              <p>the match!</p>*/}
            </> :
            <>
              <p>{winnerFull?.name}</p>
              <p>{t("[playername] won the match!")}</p>
            </>
        }
      </div>

      <div className={clsx(style.Preview)}>
        <img
          className={clsx(style.PreviewImg)}
          style={{background: winnerFull?.color}}
          src={`/avatar/breed/${winnerFull?.breed ?? 1}.png`} alt="breed icon"/>
        {
          Boolean(winnerFull?.hat) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/hat/${winnerFull?.hat}.png`} alt="hat icon"/>
        }
        {
          Boolean(winnerFullFaceDirectory) && Boolean(winnerFull?.face) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/face/${winnerFullFaceDirectory}/${winnerFull?.face}.png`} alt="face icon"/>
        }
        {
          Boolean(winnerFull?.necktie) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/accessory/${winnerFull?.necktie}.png`} alt="accessory icon"/>
        }
      </div>

      <div className={clsx(style.Footer)}>
        {
          opponentLeft ? (
            <p>{t("Opponent left!")}</p>
          ) : (
            meReadyForRematch ? (
              <>
                <p>{t("Waiting for opponent")}</p>
                <p>{t("to join")}</p>
              </>
            ) : (
              <>
                {
                  opponentReadyForRematch ?
                    <div className={clsx(style.WinMessage)}>
                      <div className={clsx(style.TreatLabel)}>
                        <p>{t("[playername] would like a rematch!", {playername: opponent?.name})}</p>
                      </div>
                    </div> :
                    <div className={clsx(style.WinMessage)}>
                      <div className={clsx(style.TreatLabel)}>
                        <p>{t("You earned")}</p>
                      </div>

                      <div className={clsx(style.Treat)}>
                        <img src={treatIcon} alt="treat icon"/>&nbsp;
                        <p>{amIWinner ? 75 : 25}</p>
                      </div>
                    </div>
                }
                <ButtonV2 theme='white' label={t('Rematch')} onClick={() => {
                  playSound('click');
                  clickReadyForMatch('public')();
                }}/>
              </>
            )
          )
        }
        <ButtonV2 theme='white' label={t('Play New Opponent')} onClick={() => {
          playSound('click');
          leave({forced: false, playNew: true});
        }}/>
      </div>
    </div>
  )
}

export default EndScreen;
