import * as React from "react";

const BreedIcon = (
  {
    fill = '#fff',
  }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53.76" height="51.73" viewBox="0 0 53.76 51.73">
      <g id="Layer_2" data-name="Layer 2">
        <g id="breed_icon" data-name="breed icon">
          <path className="cls-1"
                fill={fill}
                d="M51.67,21.56A33.38,33.38,0,0,0,48.6,1.65a3.77,3.77,0,0,0-.91-1.3,1.6,1.6,0,0,0-2.3.33S40,7.91,38.18,10.24a32.59,32.59,0,0,0-22.7,0L8.24.68A1.59,1.59,0,0,0,6,.35,3.66,3.66,0,0,0,5,1.65a33.37,33.37,0,0,0-3,20.1H2A18.09,18.09,0,0,0,0,30C0,42,12,51.73,26.88,51.73S53.76,42,53.76,30A18.22,18.22,0,0,0,51.67,21.56Z"/>
        </g>
      </g>
    </svg>
  )
}

export default BreedIcon;
