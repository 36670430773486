import * as React from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

import './App.css';
import Home from "./views/Home";
import {GameProvider} from "./providers/GameProvider";
import PlayRoom from "./views/PlayRoom";
import Settings from "./views/Settings";
import Environment from "./views/Environment";
import {useUIContext} from "./providers/UIProvider";
import PrivateGameMenu from "./views/Home/PrivateGameMemu";
import BonusModal from "./views/Home/BonusModal";
import "./i18nextInit";

function App() {
  const {visibleGear, visibleSettings, visiblePrivateGameMenu, visibleBonusModal} = useUIContext();
  return (
    <React.Fragment>
      {visibleGear && <Environment/>}
      {visibleSettings && <Settings/>}
      {visiblePrivateGameMenu && <PrivateGameMenu/>}
      {visibleBonusModal && <BonusModal/>}
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/home"
            element={<Home/>}
          />
          <Route
            path="/play-v2/:mode/:id/:session"
            element={
              <GameProvider>
                <PlayRoom/>
              </GameProvider>
            }
          />
          <Route
            path="*"
            element={<Navigate to="/home" replace/>}
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
