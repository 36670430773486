import * as React from "react";
import clsx from "clsx";
import style from "./PublicLobby.module.scss";
import {useGameContext} from "../../providers/GameProvider";
import useScale from "../../hooks/useScale";
import LobbyCommon from "./LobbyCommon";
import {useParams} from "react-router-dom";
import {ButtonV2} from "../Home";
import {getCodeFromRoomId} from "../../utils/http";
import {useUIContext} from "../../providers/UIProvider";
import closeIcon from "../../assets/images/close.png";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const PrivateLobby = () => {
  const {countdown, visibleMode, players, opponent, leave, clickReadyForMatch, me} = useGameContext();
  const meReadyForMatch = React.useMemo(() => me?.readyForMatch, [me?.readyForMatch]);
  const opponentReadyForMatch = React.useMemo(() => opponent?.readyForMatch, [opponent?.readyForMatch]);
  const {setVisibleSettings} = useUIContext();
  const {id} = useParams();
  const [ref] = useScale();
  const allJoined = players?.length === 2;
  const starting = visibleMode === 'starting';
  const [code, setCode] = React.useState('CODE');
  const {playSound} = useAudioContext();
  const {t} = useTranslation();

  React.useEffect(() => {
    let mounted = true;
    if (id) {
      getCodeFromRoomId(id)
        .then(code => {
          if (mounted)
            setCode(code);
        })
        .catch(e => console.error("get code from room id error", e));
    }

    return () => {
      setVisibleSettings(false);
      mounted = false;
    }
  }, [id]);

  const handleCopy = () => {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <div
      ref={ref} className={clsx(style.Wrapper)}
    >
      {
        !starting &&
        <img
          className={clsx(style.CloseIcon)}
          src={closeIcon} alt="close icon"
          onClick={() => {
            playSound('click');
            leave({
              forced: false,
              playNew: true,
              privateGame: true,
            });
          }}
        />
      }
      <div className={clsx(style.PlayerStatusWrapper)}>
        {
          starting ?
            <p>{countdown ? t("Match starting in [seconds]...", {seconds: countdown}) : ""}</p> : (
              opponentReadyForMatch ?
                <p>{t("Opponent ready!")}</p> :
                (
                  meReadyForMatch && <p>{t("Waiting for opponent...")}</p>
                )
            )
        }
      </div>

      <LobbyCommon isPrivate={true}/>

      {
        !starting && (
          allJoined ? (
            <div className={clsx(style.Footer)}>
              {
                !meReadyForMatch &&
                <ButtonV2
                  theme='white'
                  label={t('Start Game')}
                  onClick={clickReadyForMatch('private')}
                />
              }
            </div>
          ) : (
            <div className={clsx(style.CodeArea)}>
              <div className={clsx(style.CodeWrapper)}>
                <span>{code}</span>
              </div>

              <div className={clsx(style.ButtonWrapper)}>
                <ButtonV2
                  theme={'white'}
                  label={t('Copy Code')}
                  onClick={handleCopy}
                />
              </div>
            </div>
          )
        )
      }
    </div>
  )
}

export default PrivateLobby;
