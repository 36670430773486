import * as React from "react";

import clsx from "clsx";
import style from "./Home.module.scss";
import {useTranslation} from "react-i18next";

// assets
import treatIcon from "../../assets/images/treat.png";
import treatBagIcon from "../../assets/images/treat-bag.png";
import gearIcon from "../../assets/images/gear.png";
import titleImage from "../../assets/images/title.png";
import customizeIcon from "../../assets/images/customize-icon.png";
import useScale from "../../hooks/useScale";
import {useHomeContext} from "../../providers/HomeProvider";
import {useBaseContext} from "../../providers/BaseProvider";
import {useUIContext} from "../../providers/UIProvider";
import {useSettingContext} from "../../providers/SettingsProvider";
import {getParamFromUrl} from "../../utils";
import {useAudioContext} from "../../providers/AudioProvider";

export const ButtonV2 = (
  {
    label,
    icon,
    disabled = false,
    theme = 'white',
    onClick = () => {
    },
  }) => {
  const {playSound} = useAudioContext();
  const handleClick = () => {
    if (disabled)
      return;
    playSound('click');
    onClick();
  }
  const Avatar = () => {
    const {color, breed, hat, face, necktie, faceDirectory} = useSettingContext();
    return (
      <div className={clsx(style.Preview)}>
        {
          Boolean(breed) ?
            <img
              className={clsx(style.PreviewImg)}
              style={{background: color}}
              src={`/avatar/breed/${breed}.png`} alt="breed icon"/> :
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/breed/2.png`} alt="breed icon"/>
        }
        {
          Boolean(hat) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/hat/${hat}.png`} alt="hat icon"/>
        }
        {
          Boolean(faceDirectory) && Boolean(face) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/face/${faceDirectory}/${face}.png`} alt="face icon"/>
        }
        {
          Boolean(necktie) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/accessory/${necktie}.png`} alt="accessory icon"/>
        }
      </div>
    )
  }
  const hasIcon = Boolean(icon);
  const Theme = theme === 'white' ? style.White :
    (theme === 'brown' ? style.Brown : theme === 'dark-brown' ? style.DarkBrown : null);
  return (
    <div
      className={clsx(style.ButtonWrapper, hasIcon ? style.SpaceAround : style.Center, Theme)}
      onClick={handleClick}
    >
      {hasIcon && <Avatar/>}
      <span>{label}</span>
    </div>
  )
}

const Home = () => {
  const {t} = useTranslation();
  const {startGame} = useHomeContext();
  const {setVisibleSettings, setVisiblePrivateGameMenu, setVisibleBonusModal} = useUIContext();
  const {ableToClaim, formattedCoin, remainingTime} = useBaseContext();
  const {playSound} = useAudioContext();
  const [ref] = useScale();
  const {setVisibleGear} = useUIContext();
  const {setTab, pattern, patternColor, mitt, toe, color, loaded, faceInitialized} = useSettingContext();
  const paws = [2, 1, 3];
  React.useEffect(() => {
    if (loaded && faceInitialized) {
      const key = getParamFromUrl("newGame");
      if (key === 'private') {
        setVisiblePrivateGameMenu(true);
      } else if (key === 'public') {
        startGame('public');
      }
    }
  }, [loaded, faceInitialized]);

  const customizeProfile = () => {
    setVisibleSettings(true);
    setTab("avatar");
  }

  const gotToPawSetting = () => {
    playSound('click');
    setVisibleSettings(true);
    setTab("paw");
  }

  return (
    <div
      ref={ref}
      className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.Toolbar)}>
        <div className={clsx(style.Left)}>
          <div className={clsx(style.CurrencyWrapper)}>
            <img src={treatIcon} className={clsx(style.TreatIcon)} alt="treat icon"/>

            <span>{formattedCoin}</span>
          </div>

          <div className={clsx(style.GiftWrapper, ableToClaim ? style.AbleClaim : null)}
               onClick={() => {
                 playSound('click');
                 setVisibleBonusModal(true);
               }}>
            <img src={treatBagIcon} className={clsx(style.TreatBagIcon, ableToClaim ? style.Animate : null)}
                 alt="treat bag icon"/>
            <span>{ableToClaim ? '' : remainingTime}</span>
          </div>
        </div>

        <div className={clsx(style.SettingsWrapper)}>
          <img src={gearIcon} alt="settings icon" onClick={() => {
            playSound('click');
            setVisibleGear(true);
          }}/>
        </div>
      </div>

      <div className={clsx(style.Title)}>
        <img src={titleImage} alt="title"/>
      </div>

      <div
        className={clsx(style.Hero)}
      >
        <div className={clsx(style.HeroInner)}>
          {
            paws.map((paw, index) => (
              <div
                className={clsx(style.Pad)}
                key={`paw-${index}`}
                onClick={gotToPawSetting}
              >
                <div
                  className={clsx(style.PawPreview)}
                >
                  {
                    Boolean(mitt) &&
                    <img
                      className={clsx(style.PreviewImg)}
                      style={{background: color}}
                      src={`/paw/mitts/${mitt}/${paw}.png`} alt="paw mitts"/>
                  }
                  <img
                    className={clsx(style.PreviewImg)}
                    src={`/paw/toe/${toe ?? 1}/${paw}.png`} alt="paw toe"/>
                  {
                    Boolean(pattern) &&
                    <img
                      className={clsx(style.PreviewImg)}
                      src={`/paw/patterns/${pattern}/${patternColor}.png`}
                      alt="pattern"
                    />
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <div className={clsx(style.Footer)}>
        <ButtonV2 theme='white' label={t("Play Random Opponent")} onClick={() => startGame('public')}/>
        <ButtonV2 theme='white' label={t("Play A Friend")} onClick={() => setVisiblePrivateGameMenu(true)}/>
        <ButtonV2 theme='brown' label={t("Customize")} icon={customizeIcon} onClick={customizeProfile}/>
      </div>
    </div>
  )
}

export default Home;
