import React from "react";
import clsx from "clsx";
import style from "./LanguageModal.module.scss";
import closeIcon from "../../assets/images/close.png";
import {languages} from "./index";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const LanguageModal = (
  {
    language,
    setLanguage,
    onClose,
  }) => {
  const {playSound} = useAudioContext();
  const {t} = useTranslation();

  const Item = (
    {
      title,
      value,
      lang,
    }) => {
    return (
      <div className={clsx(style.Item, language === lang ? style.Selected : null)} onClick={() => {
        playSound('click');
        setLanguage(lang);
      }}>
        <img src={`/flag/${value}.svg`} alt={`${title} flag`}/>

        <div className={clsx(style.Label)}>
          <span>
          {title}
        </span>
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(style.Wrapper)}>
      <div className={clsx(style.Toolbar)}>
        <div/>
        <img
          src={closeIcon}
          alt="close icon"
          className={clsx(style.CloseIcon)}
          onClick={onClose}
        />
      </div>

      <div className={clsx(style.Header)}>
        {t('Select Language')}
      </div>

      <div className={clsx(style.Body)}>
        <div className={clsx(style.BodyInner)}>
          {
            languages.map(language => (
              <Item
                key={language.value}
                value={language.value}
                lang={language.lang}
                title={language.title}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default LanguageModal;
