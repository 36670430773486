import i18n from '../i18nextInit';

export const GAME_STATUS = {
  WAITING: 'waiting',
}
export const GAME_MODE = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};
export const SIGNAL = {
  WAIT: 'wait',
}
export const CHOICE_ROCK = 'rock';
export const CHOICE_PAPER = 'paper';
export const CHOICE_SCISSORS = 'scissors';
export const CHOICES = [CHOICE_ROCK, CHOICE_PAPER, CHOICE_SCISSORS];
export const LOSE_MAPPING = {
  [CHOICE_SCISSORS]: CHOICE_PAPER,
  [CHOICE_PAPER]: CHOICE_ROCK,
  [CHOICE_ROCK]: CHOICE_SCISSORS,
}
export const LOSE_MAPPING_V2 = {
  [CHOICE_SCISSORS]: 1,
  [CHOICE_PAPER]: 2,
  [CHOICE_ROCK]: 3,
}
export const CHOICE_MAPPING = {
  [CHOICE_SCISSORS]: 3,
  [CHOICE_PAPER]: 1,
  [CHOICE_ROCK]: 2,
}
export const WIN_MESSAGE = {
  [CHOICE_SCISSORS]: i18n.t("Scissors Beats Paper!"),
  [CHOICE_PAPER]: i18n.t("Paper Beats Rock!"),
  [CHOICE_ROCK]: i18n.t("Rock Beats Scissors!"),
}
export const PRODUCTS = {
  BREED: new Map([
    [1, 0],
    [7, 0],
    [6, 200],
    [2, 200],
    [12, 600],
    [9, 600], // corgi dog
    [4, 800],
    [8, 800],
    [11, 1000],
    [5, 1200],
    [10, 1600],
    [3, 2000],
  ]),
  HAT: new Map([
    [3, 300],
    [2, 300],
    [5, 500],
    [1, 500],
    [9, 800],
    [8, 1000],
    [6, 1000],
    [7, 1400],
    [4, 1600],
  ]),
  ACCESSORY: new Map([
    [1, 1000],
    [4, 1200],
    [6, 1200],
    [5, 1500],
    [2, 1800],
    [3, 2000],
  ]),
  TOES: new Map([
    [2, 0],
    [1, 200],
    [5, 200],
    [6, 500],
    [3, 1200],
    [4, 2000],
  ]),
  PATTERN: new Map([
    [5, 200],
    [4, 200],
    [6, 400],
    [1, 400],
    [7, 600],
    [3, 600],
    [9, 1000],
    [2, 1400],
    [8, 2500],
  ]),
}
export const BREED_FACES = 6;

export const numFaces = {0: 6, 1: 6, 2: 6, 3: 6, 4: 6, 5: 6, 6: 6, 7: 6, 8: 6, 9: 6, 10: 6, 11: 6}; // {breedIndex: numberOFFaces}
export const faceDirectoryMap = {0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 2, 7: 2, 8: 2, 9: 2, 10: 2, 11: 2 }; // {breedIndex: faceDirectoryName}
export const breedBaseColorMap = {0: 1, 1: 3, 2: 2, 3: 4, 4: 4, 5: 5, 6: 3, 7: 3, 8: 5, 9: 5, 10: 5, 11: 2}; // {breedIndex: basePawDirectoryName}
export const breedPatternColorMap = {0: 5, 1: 3, 2: 1, 3: 3, 4: 1, 5: 1, 6: 3, 7: 2, 8: 6, 9: 1, 10: 4, 11: 1} // {breedIndex: patternImageName}
