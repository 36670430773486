import axios from "axios";
import {httpUrl as baseUrl} from "../config";


export const instance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

export const getCodeForPrivateGame = () => {
  return new Promise((resolve, reject) => {
    instance.get("/code")
      .then(res => resolve(res.data))
      .catch(e => reject(e));
  });
}

export const getRoomIdFromCode = code => {
  return new Promise((resolve, reject) => {
    instance.get(`/room/${code}`)
      .then(res => resolve(res.data))
      .catch(e => reject(e));
  });
}

export const getCodeFromRoomId = room => {
  return new Promise((resolve, reject) => {
    instance.get(`/code/${room}`)
      .then(res => resolve(res.data))
      .catch(e => reject(e));
  });
}
