export const getItem = key => {
  try {
    const item = localStorage?.getItem(key);
    if ([null, undefined, 'null', 'undefined', 'NaN'].includes(item?.toString()))
      return null;
    else return item;
  } catch (e) {
    return null;
  }
};

export const setItem = (key, value) => {
  try {
    localStorage?.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

export const removeItem = key => {
  try {
    localStorage?.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

export const parse = str => {
  try {
    const result = JSON.parse(str);
    if (!result) throw new Error("Empty Obj String");
    return result;
  } catch (e) {
    return {};
  }
}