import * as React from "react";
import clsx from "clsx";
import style from "./PublicLobby.module.scss";
import {useGameContext} from "../../providers/GameProvider";
import useScale from "../../hooks/useScale";
import LobbyCommon from "./LobbyCommon";
import {useTranslation} from "react-i18next";

const PublicLobby = () => {
  const {countdown, visibleMode} = useGameContext();
  const [ref] = useScale();
  const starting = visibleMode === 'starting';
  const {t} = useTranslation();

  return (
    <div
      ref={ref} className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.PlayerStatusWrapper)}>
        {
          starting ?
            <p>{countdown ? t("Match starting in [seconds]...", {seconds: countdown}) : ""}</p> :
            <p>{t("Finding opponent...")}</p>
        }
      </div>
      <LobbyCommon/>
    </div>
  )
}

export default PublicLobby;
