import * as React from "react";
import Modal from "react-modal";
import clsx from "clsx";
import style from "./QuitModal.module.scss";
import {useTranslation} from "react-i18next";

const ButtonSx = (
  {
    label,
    variant,
    onClick = () => {},
  }) => {
  const theme = React.useMemo(() => {
    if (variant === 'brown') return style.Brown;
    else if (variant === 'light-pink') return style.LightPink;
    return null;
  }, [variant]);
  return (
    <div className={clsx(style.ButtonWrapper, theme)} onClick={onClick}>
      {label}
    </div>
  )
}

const QuitModal = (
  {
    visible,
    onOk = () => {},
    onCancel = () => {},
  }) => {
  const {t} = useTranslation();
  return (
    <Modal
      isOpen={visible}
      className={clsx(style.QuitModal)}
      overlayClassName={clsx(style.OverlayModal)}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={true}
      appElement={document.getElementsByTagName("body")}
    >
      <div className={clsx(style.Title)}>
        {t("Are you sure you want to quit?")}
      </div>

      <div className={clsx(style.Footer)}>
        <ButtonSx label={t('Cancel')} variant={'brown'} onClick={onCancel}/>
        <ButtonSx label={t('Quit')} variant={'light-pink'} onClick={onOk}/>
      </div>
    </Modal>
  )
}

export default QuitModal;
