import * as React from "react";
import clsx from "clsx";
import style from "./Environment.module.scss";
import useScale from "../../hooks/useScale";
import soundIcon from "../../assets/images/sound.png";
import soundMuteIcon from "../../assets/images/sound-mute.png";
import closeIcon from "../../assets/images/close.png";
import LanguageModal from "./LanguageModal";
import ReactSlider from 'react-slider'
import {useUIContext} from "../../providers/UIProvider";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

export const languages = [
  {
    title: 'en(us)',
    value: 'en',
    lang: 'en',
  },
  {
    title: 'es',
    value: 'es',
    lang: 'es',
  },
  {
    title: 'fr',
    value: 'fr',
    lang: 'fr',
  },
  {
    title: 'pt-br',
    value: 'pt-br',
    lang: 'ptbr',
  },
  {
    title: 'it',
    value: 'it',
    lang: 'it',
  },
  /*{
    title: 'de',
    value: 'de',
  },
  {
    title: 'cn',
    value: 'cn',
  },*/
];


const Environment = () => {
  const [ref] = useScale();
  const {toggleMute, mute, volume, setVolume} = useAudioContext();
  const lang = localStorage.getItem("lang") ?? 'en';
  const [language, setLanguage] = React.useState(lang);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const languageLabel = languages.find(it => it.value === language)?.title;
  const {setVisibleGear} = useUIContext();
  const {playSound} = useAudioContext();
  const { t, i18n } = useTranslation();

  const languageFlag = React.useMemo(() => languages.find(v => v.lang === language)?.value ?? 'en', [lang]);

  React.useEffect(() => {
    i18n.changeLanguage(language).then();
    localStorage.setItem("lang", language);
  }, [language]);

  return (
    <div ref={ref} className={clsx(style.Wrapper)}>
      <div className={clsx(style.Toolbar)}>
        <div/>
        <img
          src={closeIcon}
          alt="close icon"
          className={clsx(style.CloseIcon)}
          onClick={() => {
            playSound('click');
            setVisibleGear(false);
          }}
        />
      </div>

      <div className={clsx(style.SoundWrapper)}>
        <div>
          <span>
            {t("Sound")}
          </span>
        </div>

        <div className={clsx(style.SoundSliderWrapper)}>

          <div className={clsx(style.ItemWrapper)}>
            <img
              src={mute ? soundMuteIcon : soundIcon}
              alt="sound icon"
              onClick={() => toggleMute()}
            />

            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              min={0}
              max={100}
              defaultValue={volume}
              trackClassName="example-track"
              onChange={(value) => setVolume(value)}
            />
          </div>
        </div>
      </div>

      <div className={clsx(style.LanguageWrapper)}>
        <div className={clsx(style.Header)}>
          <span>
            {t("Language")}
          </span>
        </div>

        <div className={clsx(style.FlagWrapper)}>
          <img
            src={`/flag/${languageFlag}.svg`}
            alt={`${languageFlag} flag`}
            onClick={() => {
              playSound('click');
              setVisibleModal(true);
            }}
          />
          <span>
            {languageLabel}
          </span>
        </div>
      </div>

      {
        visibleModal &&
        <LanguageModal language={language} setLanguage={setLanguage} onClose={() => {
          playSound('click');
          setVisibleModal(false);
        }}/>
      }
    </div>
  )
}

export default Environment;
