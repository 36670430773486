import React from "react";
import clsx from "clsx";
import style from "./OpponentLeft.module.scss";
import {ButtonV2} from "../Home";
import {useGameContext} from "../../providers/GameProvider";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const OpponentLeft = () => {
  const {leave} = useGameContext();
  const {playSound} = useAudioContext();
  const {t} = useTranslation();

  return (
    <div className={clsx(style.Wrapper)}>
      <div className={clsx((style.Body))}>
        <div className={clsx(style.Header)}>
          <p>
            {t('Opponent left!')}
          </p>
        </div>
        <div className={clsx(style.ButtonWrapper)}>
          <ButtonV2
            label={t('Create New Game')}
            theme={'white'}
            onClick={() => {
              playSound('click');
              leave({forced: false, playNew: true, privateGame: true});
            }}/>
          <ButtonV2
            label={t('Return To Menu')}
            theme={'white'}
            onClick={() => {
              playSound('click');
              leave({forced: true, playNew: false});
            }}/>
        </div>
      </div>
    </div>
  )
}

export default OpponentLeft;
