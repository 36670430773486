import * as React from "react";
import tickIcon from "../../assets/images/tick.svg";
import clsx from "clsx";
import style from "./ColorPicker.module.scss";
import {useSettingContext} from "../../providers/SettingsProvider";
import useClickOutside from "../../hooks/useClickOutside";
import {useAudioContext} from "../../providers/AudioProvider";

const ColorPicker = () => {
  const {colors, color, setColorDeep} = useSettingContext();
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);
  useClickOutside(ref, () => setVisible(false));
  const {playSound} = useAudioContext();

  const ColorItem = (
    {
      color,
      picked,
    }) => {
    return (
      <div className={clsx(style.ColorItem)} style={{background: color}} onClick={() => {
        playSound('click');
        setColorDeep(color);
      }}>
        {
          picked && <img src={tickIcon} alt="tick icon"/>
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        className={clsx(style.Wrapper)}
        style={{background: color}}
        onClick={() => {
          playSound('click');
          setVisible(true);
        }}
      />
      {
        visible &&
        <div className={clsx(style.Popup)} ref={ref}>
          {
            colors.map(it => (
              <ColorItem color={it} picked={color === it} key={it}/>
            ))
          }
        </div>
      }
    </React.Fragment>
  )
}

export default ColorPicker;
