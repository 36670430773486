import * as React from "react";
import pricePawIcon from "../../assets/images/price-paw.png";
import style from "./PriceLabel.module.scss";
import clsx from "clsx";

const PriceLabel = (
  {
    disabled = false,
    price,
    onPaw = false,
  }) => {
  return (
    <div className={clsx(style.Wrapper, disabled ? style.Disable : null, onPaw ? style.OnPawPosition : null)}>
      <img src={pricePawIcon} alt="price paw"/>
      <span>{price}</span>
    </div>
  )
}

export default PriceLabel;
