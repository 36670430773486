import * as React from "react";
import Resize from "../utils/Resize";

function useScale() {
  const resize = new Resize();
  const ref = React.useRef(null);

  React.useEffect(() => {
    resize.resize(ref, 428, 926);

    window.addEventListener('resize', handleScreenSizeChange);
    return () => {
      window.removeEventListener('resize', handleScreenSizeChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const handleScreenSizeChange = () => resize.resize(ref, 428, 926);

  return [ref];
}

export default useScale;
