import * as React from "react";
import {getItem, setItem} from "../utils/Storage";
import spacetime from "spacetime";

const BaseContext = React.createContext(null);

export const BaseProvider = ({children}) => {
  const [bonusAt, setBonusAt] = React.useState(undefined);
  const [coin, setCoin] = React.useState(600);
  const bonusUnit = 200;

  React.useEffect(() => {
    const savedBonusAt = getItem("rps-bonus-time");
    if (Boolean(savedBonusAt))
      setBonusAt(parseInt(savedBonusAt));
    const savedCoin = getItem("rps-coin");
    if (Boolean(savedCoin))
      setCoin(parseInt(savedCoin));
  }, []);

  const timezone = 'America/New_York';

  const ableToClaim = React.useMemo(() => {
    if (bonusAt) {
      let a = spacetime(bonusAt);
      a = a.goto(timezone);
      let today = spacetime();
      today = today.goto(timezone);

      return a.diff(today, 'day') > 0;
    }

    return true;
  }, [bonusAt]);

  const remainingTime = React.useMemo( () => {
    if (ableToClaim) {
      return '';
    } else {
      let now = spacetime();
      now = now.goto(timezone);
      const nextDay = now.next('date');
      const diff = now.diff(nextDay, 'minute');
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      const formattedHours = hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      const formattedMinutes = minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      return `${formattedHours}h ${formattedMinutes}m`;
    }
  }, [bonusAt, ableToClaim]);

  const handleClaim = () => {
    if (ableToClaim) {
      setCoin(prevState => prevState + bonusUnit);
      setItem("rps-coin", coin + bonusUnit);
      const d = new Date().getTime();
      setBonusAt(d);
      setItem("rps-bonus-time", d);
    }
  }

  const setCoinDeep = v => {
    setCoin(v);
    setItem("rps-coin", v);
  }

  const formattedCoin = React.useMemo(() => coin ? coin.toLocaleString('en-US') : '0', [coin]);

  const providerValue = {
    ableToClaim,
    handleClaim,
    coin,
    formattedCoin,
    bonusUnit,
    setCoinDeep,
    remainingTime,
  };

  return (
    <BaseContext.Provider value={providerValue}>
      {children}
    </BaseContext.Provider>
  )
};

export const useBaseContext = () => {
  const context = React.useContext(BaseContext);
  if (!context) {
    throw new Error("useBaseContext must be used within BaseProvider");
  }
  return context;
};
