import * as React from "react";
import {useSettingContext} from "../../providers/SettingsProvider";
import crossCircleIcon from "../../assets/images/cross-circle.png";
import clsx from "clsx";
import style from "./AssetItem.module.scss";
import pricePawIcon from "../../assets/images/price-paw.png";
import {useBaseContext} from "../../providers/BaseProvider";
import {useTranslation} from "react-i18next";

const AssetItem = (
  {
    type,
    picked,
    index,
    orderIndex,
    imageUrl,
  }) => {
  const {
    handleBuy,
    handleUnEquip,
    emptyAsDefault,
    handleClick,
    getPrice,
    checkIfBought,
  } = useSettingContext();
  const {coin} = useBaseContext();
  const {t} = useTranslation();

  const price = getPrice({type, index});
  const bought = checkIfBought({type, index});
  const free = price === 0;
  const ableToUnEquip = emptyAsDefault.includes(type) && picked && (bought || price === 0);
  const ableToBuy = coin >= price;
  const visibleBadge = !free && !bought && picked;

  return (
    <div
      className={clsx(style.ContentWrapper, picked ? style.Picked : null)}
      onClick={() => handleClick({type, index, bought})}
    >
      {
        visibleBadge &&
          <div className={clsx(style.Info, !ableToBuy ? style.UnableToBuy : null)}>
            <img src={pricePawIcon} alt="price paw icon"/>
            <span className={clsx(style.PriceLabel)}>{price}</span>
          </div>
      }
      {
        ableToUnEquip &&
        <img
          src={crossCircleIcon}
          className={clsx(style.UnEquipIcon)}
          alt="unequip icon"
          onClick={(e) => {
            handleUnEquip({type});
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      }
      <div className={clsx(style.ImageWrapper)}>
        <img
          className={clsx(style.Img)}
          src={`${imageUrl}`} alt="img icon"
        />
      </div>
      {
        price !== 0 && (
          picked ?
            (
              <div className={clsx(style.BuyWrapper)}>
                {
                  bought ?
                    <div className={clsx(style.Tick)}><span>✔</span></div> :
                    <div
                      className={clsx(style.BuyInnerWrapper, !ableToBuy ? style.Disabled : null)}
                      onClick={(e) => {
                        if (ableToBuy) handleBuy({type, index, orderIndex});
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <span>{t("Buy")}</span>
                    </div>
                }
              </div>
            )
            :
            <div className={clsx(style.PriceWrapper)}>
              {
                bought ?
                  <div className={clsx(style.Tick)}><span>✔</span></div> :
                  <React.Fragment>
                    <img src={pricePawIcon} alt="price paw icon"/>
                    <span className={clsx(style.PriceLabel)}>{price}</span>
                  </React.Fragment>
              }
            </div>
        )
      }
    </div>
  )
}

export default AssetItem;
