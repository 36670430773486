import * as React from "react";
import clsx from "clsx";
import style from "./LobbyCommon.module.scss";
import {useSettingContext} from "../../providers/SettingsProvider";
import {useGameContext} from "../../providers/GameProvider";
import {useUIContext} from "../../providers/UIProvider";
import loadingPrints from "../../assets/images/loading-prints.gif";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const LobbyCommon = (
  {
    isPrivate = false,
  }) => {
  const {t} = useTranslation();
  const {me, opponent, opponentFaceDirectory, meFaceDirectory, visibleMode,} = useGameContext();
  const starting = visibleMode === 'starting';
  const meReadyForMatch = React.useMemo(() => me?.readyForMatch, [me?.readyForMatch]);
  const {color, setTab} = useSettingContext();
  const {setVisibleSettings} = useUIContext();
  const unableToSettings = React.useMemo(() => !isPrivate || starting || meReadyForMatch, [isPrivate, meReadyForMatch, starting]);
  const handleClick = () => {
    if (!unableToSettings) {
      setVisibleSettings(true);
      setTab('avatar');
    }
  }
  const {playSound} = useAudioContext();

  React.useEffect(() => {
    if (!isPrivate && opponent?.id) {
      playSound("match-found");
    }
  }, [opponent?.id, isPrivate]);

  return (
    <React.Fragment>
      <div className={clsx(style.Player, style.Opponent)}>
        {
          Boolean(opponent?.id) ?
            <div className={clsx(style.Preview)}>
              {
                Boolean(opponent?.breed) ?
                  <img
                    className={clsx(style.PreviewImg)}
                    style={{background: opponent.color}}
                    src={`/avatar/breed/${opponent.breed}.png`} alt="breed icon"/> :
                  <img
                    className={clsx(style.PreviewImg)}
                    src={`/avatar/breed/2.png`} alt="breed icon"/>
              }
              {
                Boolean(opponent?.hat) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/hat/${opponent.hat}.png`} alt="hat icon"/>
              }
              {
                Boolean(opponentFaceDirectory) && Boolean(opponent?.face) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/face/${opponentFaceDirectory}/${opponent.face}.png`} alt="face icon"/>
              }
              {
                Boolean(opponent?.necktie) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/accessory/${opponent.necktie}.png`} alt="accessory icon"/>
              }
            </div> :
            <div className={clsx(style.LoadingPrintsWrapper)}>
              <img
                src={loadingPrints} alt="loading prints"/>
            </div>
        }
        <div className={clsx(style.NameWrapper)}>
          {
            Boolean(opponent?.name) &&
            <span>{opponent?.name}</span>
          }
        </div>
      </div>

      <div className={clsx(style.VersusWrapper)}>
        <span>VS</span>
      </div>

      <div className={clsx(style.Player)}>
        <div className={clsx(style.Preview, unableToSettings ? style.NoBorder : style.CursorPoint)} onClick={handleClick}>
          {
            Boolean(me?.breed) &&
            <img
              className={clsx(style.PreviewImg)}
              style={{background: color}}
              src={`/avatar/breed/${me.breed}.png`} alt="breed icon"/>
          }
          {
            Boolean(me?.hat) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/hat/${me.hat}.png`} alt="hat icon"/>
          }
          {
            Boolean(meFaceDirectory) && Boolean(me?.face) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/face/${meFaceDirectory}/${me.face}.png`} alt="face icon"/>
          }
          {
            Boolean(me?.necktie) &&
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/accessory/${me.necktie}.png`} alt="accessory icon"/>
          }
        </div>

        <div className={clsx(style.NameWrapper)}>
          {t("You")}
        </div>
      </div>
    </React.Fragment>
  )
}

export default LobbyCommon;
