import React, {useState, useEffect, useContext} from "react";
import NameGenerator from "../utils/NameGenerator";
import {curses, numericCurses} from "../data/curses";
import {getItem, removeItem, setItem} from "../utils/Storage";
import {
  numFaces,
  faceDirectoryMap,
  CHOICE_ROCK,
  CHOICE_PAPER,
  CHOICE_SCISSORS,
  PRODUCTS,
  BREED_FACES,
  breedBaseColorMap, breedPatternColorMap
} from "../constant";
import {parse} from "../utils/Storage";
import {useBaseContext} from "./BaseProvider";
import {useAudioContext} from "./AudioProvider";

const SettingContext = React.createContext(null);

export const SettingProvider = ({children}) => {
  const {playSound} = useAudioContext();
  const [tab, setTab] = React.useState('avatar'); // avatar, paw (used in settings screen)
  const [footerTab, setFooterTab] = React.useState('breed'); // breed, face, hat, necktie, mitts, toe
  const [publicName, setPublicName] = useState("");
  const nameGenerator = new NameGenerator();
  const [loaded, setLoaded] = useState(false);
  const [breed, setBreed] = useState(undefined);
  const [breedPreview, setBreedPreview] = useState(undefined);
  const [face, setFace] = useState(undefined);
  const [facePreview, setFacePreview] = useState(undefined);
  const [hat, setHat] = useState(undefined);
  const [hatPreview, setHatPreview] = useState(undefined);
  const [necktie, setNecktie] = useState(undefined);
  const [necktiePreview, setNecktiePreview] = useState(undefined);
  const [pattern, setPattern] = useState(undefined);
  const [patternPreview, setPatternPreview] = useState(undefined);
  const [toe, setToe] = useState(undefined);
  const [toePreview, setToePreview] = useState(undefined);
  const colors = ['#7A76E0', '#E2A2CB', '#E0B65E', '#5CC6AA', '#4CB4CC'];
  const [color, setColor] = useState('#7A76E0');
  const {coin, setCoinDeep} = useBaseContext();
  const [faceInitialized, setFaceInitialized] = useState(false);
  const emptyAsDefault = [
    "hat",
    "necktie",
    "pattern",
  ];
  const [assetsBought, setAssetsBought] = useState({
    breed: [],
    face: [],
    hat: [],
    necktie: [],
    toe: [],
    pattern: [],
  });

  useEffect(() => {
    const savedPublicName = getItem("rps-public-name");
    if (savedPublicName)
      setPublicName(savedPublicName);
    else {
      initializeV2([{storageName: 'rps-public-name', setter: setPublicName}]);
      // initialize([{storageName: 'rps-public-name', setter: setPublicName}]);
    }
    const savedBgColor = getItem("rps-bg-color");
    if (savedBgColor)
      setColor(savedBgColor);
    else {
      initializeV2([{storageName: 'rps-bg-color', setter: setColor}]);
    }
    const savedAssetsBought = getItem("rps-assets-bought");
    let savedAssetsBoughtObj = {}
    try {
      if (savedAssetsBought) {
        savedAssetsBoughtObj = JSON.parse(savedAssetsBought);
        setAssetsBought(savedAssetsBoughtObj);
      }
    } catch (e) {

    }

    const items = [
      {
        storageName: 'rps-breed',
        type: 'breed',
        setter: setBreed,
      },
      {
        storageName: 'rps-hat',
        type: 'hat',
        setter: setHat,
      },
      {
        storageName: 'rps-necktie',
        type: 'necktie',
        setter: setNecktie,
      },
      {
        storageName: 'rps-toe',
        type: 'toe',
        setter: setToe,
      },
      {
        storageName: 'rps-pattern',
        type: 'pattern',
        setter: setPattern,
      },
    ];

    items.forEach(item => {
      const savedValue = getItem(item.storageName);
      if (
        ![undefined, "undefined", null, "null", ""].includes(savedValue?.toString()) &&
        parseInt(savedValue) >= 0
      ) {
        if (getPrice({type: item.type, index: parseInt(savedValue)}) !== 0) { // if premium asset
          if (savedAssetsBoughtObj[item.type]?.some(it => it.toString() === savedValue?.toString())) { // if bought
            item.setter(parseInt(savedValue));
          } else {
            initializeV2([item]);
          }
        } else {
          item.setter(parseInt(savedValue));
        }
      } else {
        initializeV2([item]);
      }
    });

    setLoaded(true);
  }, []);

  useEffect(() => {
    if (![undefined, "undefined", null, "null", ""].includes(breed?.toString())) {
      const items = [{
        storageName: 'rps-face',
        type: 'face',
        setter: setFace,
      },];
      items.forEach(item => {
        const savedValue = getItem(item.storageName);
        if (
          ![undefined, "undefined", null, "null", ""].includes(savedValue?.toString()) &&
          parseInt(savedValue) >= 0
        ) {
          if (getPrice({type: item.type, index: parseInt(savedValue)}) !== 0) { // if premium asset
            if (assetsBought[item.type]?.some(it => it.toString() === savedValue?.toString())) { // if bought
              item.setter(parseInt(savedValue));
            } else {
              initializeV2([item]);
            }
          } else {
            item.setter(parseInt(savedValue));
          }
        } else {
          initializeV2([item]);
        }
      });
      setFaceInitialized(true);
    }
    setBreedPreview(breed);
  }, [breed, tab, footerTab]);
  useEffect(() => {
    setHatPreview(hat);
  }, [hat, tab, footerTab]);
  useEffect(() => {
    setFacePreview(face);
  }, [face, tab, footerTab]);
  useEffect(() => {
    setNecktiePreview(necktie);
  }, [necktie, tab, footerTab]);
  useEffect(() => {
    setToePreview(toe);
  }, [toe, tab, footerTab]);
  useEffect(() => {
    setPatternPreview(pattern);
  }, [pattern, tab, footerTab]);
  const mitt = React.useMemo(() => {
    if (Boolean(breed)) {
      // swap with pattern color if Mitts pattern selected except for Corgi dog
      if (patternPreview === 4) {
        if (breed === 9) {
          return breedBaseColorMap[breed - 1] ? breedBaseColorMap[breed - 1] : null;
        } else
          return breedPatternColorMap[breed - 1] ? breedPatternColorMap[breed - 1] : null;
      } else {
        return breedBaseColorMap[breed - 1] ? breedBaseColorMap[breed - 1] : null;
      }
    }
    return null;
  }, [breed, patternPreview]);
  const patternColor = React.useMemo(() => {
    if (Boolean(breed)) {
      // swap with base color if Mitts pattern selected except for Corgi dog
      if (patternPreview === 4) {
        if (breed === 9) {
          return breedPatternColorMap[breed - 1] ? breedPatternColorMap[breed - 1] : null;
        } else
          return breedBaseColorMap[breed - 1] ? breedBaseColorMap[breed - 1] : null;
      } else {
        return breedPatternColorMap[breed - 1] ? breedPatternColorMap[breed - 1] : null;
      }
    }
    return null;
  }, [breed, patternPreview]);

  const initializeV2 = (keys) => {
    keys?.forEach((key) => {
      if (key.storageName === 'rps-public-name') {
        const newIndex = nameGenerator.generateName();
        setItem(key.storageName, newIndex);
        key.setter(newIndex);
      } else if (key.storageName === 'rps-bg-color') {
        const color = colors[Math.floor(Math.random() * colors.length)]
        setItem(key.storageName, color);
        key.setter(color);
      } else if (key.storageName === 'rps-breed') {
        const keys = Array.from(PRODUCTS.BREED.keys());
        const items = [];
        keys.forEach(it => {
          if (PRODUCTS.BREED.get(it) === 0)
            items.push(parseInt(it));
        });
        if (items.length > 0) {
          const newIndex = items[Math.floor(Math.random() * items.length)];
          setItem(key.storageName, newIndex);
          key.setter(newIndex);
        }
      } else if (key.storageName === 'rps-face') {
        const newIndex = 2;
        setItem(key.storageName, newIndex);
        key.setter(newIndex);
      } else if (key.storageName === 'rps-hat') {
      } else if (key.storageName === 'rps-necktie') {
      } else if (key.storageName === 'rps-pattern') {
        const keys = Array.from(PRODUCTS.PATTERN.keys());
        const items = [];
        keys.forEach(it => {
          if (PRODUCTS.PATTERN.get(it) === 0)
            items.push(parseInt(it));
        });
        if (items.length > 0) {
          const newIndex = items[Math.floor(Math.random() * items.length)];
          setItem(key.storageName, newIndex);
          key.setter(newIndex);
        }
      } else if (key.storageName === 'rps-toe') {
        const keys = Array.from(PRODUCTS.TOES.keys());
        const items = [];
        keys.forEach(it => {
          if (PRODUCTS.TOES.get(it) === 0)
            items.push(parseInt(it));
        });
        if (items.length > 0) {
          const newIndex = items[Math.floor(Math.random() * items.length)];
          setItem(key.storageName, newIndex);
          key.setter(newIndex);
        }
      }
    })
  }

  const mapNumberToCharacters = {
    "1": ["i", "l"],
    "3": ["e"],
    "4": ["a"],
    "5": ["s"],
    "7": ["t"],
    "8": ["b"],
    "9": ["g"],
    "0": ["o"],
  };

  const includeCurse = (str) => {
    for (let i = 0; i < curses.length; i++) {
      if (str.toLowerCase().includes(curses[i])) {
        return true;
      }
    }

    return false;
  };

  const includeNumericCurse = (str) => {
    for (let i = 0; i < numericCurses.length; i++) {
      if (str.toLowerCase().includes(numericCurses[i])) {
        return true;
      }
    }

    return false;
  };

  const validate = (value) => {
    if (value?.length > 2) {
      // const regex = /^[a-zA-Z0-9]+$/i;
      const regex = /^[a-zA-Z0-9\s]*$/;
      if (regex.test(value)) {
        if (includeNumericCurse(value)) {
          return false;
        }

        let dummy = [];
        // operation result a55 will be => [a, as, a5, as5, a55, ass, a5s]
        for (let i = 0; i < value.length; i++) {
          let possibleReplaces = [value.charAt(i)];
          if (!isNaN(parseInt(value.charAt(i)))) {
            if (mapNumberToCharacters[value.charAt(i)]) {
              possibleReplaces = [...mapNumberToCharacters[value.charAt(i)], value.charAt(i)];
            }
          }

          const newDummy = JSON.parse(JSON.stringify(dummy));
          for (let j = 0; j < possibleReplaces.length; j++) {
            if (dummy.length > 0) {
              for (let k = 0; k < dummy.length; k++) {
                if (dummy[k].length === i) {
                  const newItem = `${dummy[k]}${possibleReplaces[j]}`;
                  if (newItem.length >= 3) {
                    if (includeCurse(newItem)) {
                      return false;
                    }
                  }
                  newDummy.push(`${dummy[k]}${possibleReplaces[j]}`);
                }
              }
            } else {
              newDummy.push(possibleReplaces[j]);
            }
          }
          dummy = JSON.parse(JSON.stringify(newDummy));
        }
        return true;
      } else {
        console.log('regex validation error');
        return false;
      }
    } else {
      console.log('string should be longer than 3 letters');
      return false;
    }
  };

  const checkNameValidity = (name) => {
    if (name) {
      if (name?.length > 50) {
        return false;
      }
      return validate(name);
    } else {
      return false;
    }
  };

  const winStreaksStr = localStorage.getItem("win-streaks");
  const picksStr = localStorage.getItem("picks");
  const picks = parse(picksStr);
  const historyPicks = {
    [CHOICE_SCISSORS]: parseInt(picks[CHOICE_SCISSORS]),
    [CHOICE_PAPER]: parseInt(picks[CHOICE_PAPER]),
    [CHOICE_ROCK]: parseInt(picks[CHOICE_ROCK]),
  };
  const winStreaks = React.useMemo(() => {
    return [undefined, null, ""].includes(winStreaksStr) ? 0 : parseInt(winStreaksStr);
  }, [winStreaksStr]);

  const setHatDeep = v => {
    setHat(v);
    setHatPreview(v);
    if (Boolean(v)) {
      setItem("rps-hat", v);
    } else {
      removeItem("rps-hat");
    }
  }

  const setBreedDeep = v => {
    setBreed(v);
    setItem("rps-breed", v);
  }

  const setFaceDeep = v => {
    setFace(v);
    setItem("rps-face", v);
  }

  const setColorDeep = v => {
    setColor(v);
    setItem("rps-bg-color", v);
  }

  const setNecktieDeep = v => {
    setNecktie(v);
    setItem("rps-necktie", v);
  }

  const setPatternDeep = v => {
    setPattern(v);
    setItem("rps-pattern", v);
  }

  const setToeDeep = v => {
    setToe(v);
    setItem("rps-toe", v);
  }

  const regenerateName = () => {
    const newName = nameGenerator.generateName();
    setPublicName(newName);
    setItem("rps-public-name", newName);
  }

  const setter = {
    'breed': [setBreedDeep, setBreedPreview],
    'face': [setFaceDeep, setFacePreview],
    'hat': [setHatDeep, setHatPreview],
    'necktie': [setNecktieDeep, setNecktiePreview],
    'toe': [setToeDeep, setToePreview],
    'pattern': [setPatternDeep, setPatternPreview],
  };

  const handleBuy = ({type, index, orderIndex}) => {
    let newAssetsBought = {};
    const price = getPrice({type, index});
    const bought = checkIfBought({type, index});
    const ableToBuy = coin >= price;
    if (!bought && ableToBuy) {
      const newIndex = index;
      if (setter?.[type]?.[0]) setter?.[type]?.[0](index);
      newAssetsBought = {...assetsBought, [type]: [...(assetsBought[type] ?? []), newIndex]};
      setAssetsBought(newAssetsBought);
      setItem('rps-assets-bought', JSON.stringify(newAssetsBought));
      // decrease coin
      setCoinDeep(coin - price);
      playSound("buy");

      // emit data event
      const typeToPrefix = {
        'breed': 'B',
        'hat': 'H',
        'necktie': 'A',
        'pattern': 'S',
        'toe': 'B',
      }

      if (typeToPrefix[type]) {
        const digits = orderIndex.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });

        if (digits) {
          if (window.parent && window.parent.postMessage) {
            const eventValue = `${typeToPrefix[type]}${digits}`;
            if (eventValue) {
              window.parent.postMessage(JSON.stringify({eventType: "dataEvent", key: "data", value: eventValue}), "*");
              console.log('data event triggered');
            }
          }
        }
      }
    }
  }

  const handleUnEquip = ({type}) => {
    if (type === 'breed') {
    } else if (type === 'face') {
    } else if (type === 'hat') {
      setHatDeep(undefined);
    } else if (type === 'necktie') {
      setNecktieDeep(undefined);
    } else if (type === 'pattern') {
      setPatternDeep(undefined);
    } else if (type === 'toe') {
    }
  }

  const getPrice = ({type, index}) => {
    switch (type) {
      case 'breed':
        return PRODUCTS.BREED.get(index);
      case 'face':
        return 0;
      case 'hat':
        return PRODUCTS.HAT.get(index);
      case 'necktie':
        return PRODUCTS.ACCESSORY.get(index);
      case 'pattern':
        return PRODUCTS.PATTERN.get(index);
      case 'toe':
        return PRODUCTS.TOES.get(index);
      default:
        return 0;
    }
  }

  const handleClick = ({type, index, bought}) => {
    playSound('click');
    const price = getPrice({type, index});

    if (price === 0 || bought) {
      if (setter?.[type]?.[0]) setter?.[type]?.[0](index);
    }
    if (setter?.[type]?.[1]) setter?.[type]?.[1](index);
  }

  const checkIfBought = ({type, index}) => {
    return assetsBought?.[type]?.includes(index);
  }


  const faces = [...Array(numFaces[breedPreview - 1]).keys()];
  const faceDirectory = faceDirectoryMap[breedPreview - 1];

  const providerValue = {
    publicName,
    loaded,
    checkNameValidity,
    historyPicks,
    winStreaks,
    breed,
    breedPreview,
    face,
    facePreview,
    necktie,
    necktiePreview,
    hat,
    hatPreview,
    mitt,
    toe,
    toePreview,
    pattern,
    patternPreview,
    colors,
    color,
    setColorDeep,
    setHatDeep,
    setBreedDeep,
    setFaceDeep,
    setNecktieDeep,
    setToeDeep,
    setPatternDeep,
    regenerateName,
    handleBuy,
    handleUnEquip,
    emptyAsDefault,
    assetsBought,
    handleClick,
    getPrice,
    checkIfBought,
    faceInitialized,
    tab,
    setTab,
    faces,
    faceDirectory,
    patternColor,
    footerTab,
    setFooterTab,
  };

  return (
    <SettingContext.Provider value={providerValue}>
      {children}
    </SettingContext.Provider>
  )
};

export const useSettingContext = () => {
  const context = useContext(SettingContext);
  if (!context) {
    throw new Error("useSettingContext must be used within SettingProvider");
  }
  return context;
};