import * as React from "react";
import clsx from "clsx";
import style from "./BonusModal.module.scss";
import treatBagSvgIcon from "../../assets/images/treat-bag.svg";
import treatIcon from "../../assets/images/treat.png";
import {ButtonV2} from "./index";
import {useBaseContext} from "../../providers/BaseProvider";
import {useUIContext} from "../../providers/UIProvider";
import useScale from "../../hooks/useScale";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const BonusModal = () => {
  const {ableToClaim, bonusUnit, remainingTime, handleClaim} = useBaseContext();
  const {playSound} = useAudioContext();
  const {setVisibleBonusModal} = useUIContext();
  const [ref] = useScale();
  const {t} = useTranslation();

  const handleClick = () => {
    if (ableToClaim) {
      handleClaim();
      playSound('claim');
    }
    setVisibleBonusModal(false);
  }

  return (
    <div
      ref={ref}
      className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.Header)}>
          <span>
            {t("Daily Bonus")}
          </span>
      </div>

      <div className={clsx(style.Body)}>
        <img src={treatBagSvgIcon} alt={'treat bag'}/>
        <div className={clsx(style.Info)}>
              <span>
                {
                  ableToClaim ? t("You received") : t('Come back in')
                }
              </span>
        </div>

        {
          ableToClaim ?
            <div className={clsx(style.TreatWrapper)}>
              <img src={treatIcon} alt="treat icon"/>&nbsp;&nbsp;
              <span>{bonusUnit}</span>
            </div> :
            <div className={clsx(style.RemainingWrapper)}>
              <span>{remainingTime}</span>
            </div>
        }
      </div>

      <div className={clsx(style.Footer)}>
        <ButtonV2
          theme='white'
          label={ableToClaim ? t('Claim') : t('Return To Menu')}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}

export default BonusModal;
