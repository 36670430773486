import * as React from "react";
import useScale from "../../hooks/useScale";
import style from "./Playground.module.scss";
import clsx from "clsx";
import {useGameContext} from "../../providers/GameProvider";
import {
  CHOICE_MAPPING,
  CHOICE_PAPER,
  CHOICE_ROCK,
  CHOICE_SCISSORS, CHOICES,
  LOSE_MAPPING_V2, WIN_MESSAGE,
  faceDirectoryMap,
} from "../../constant";
import pauseIcon from "../../assets/images/pause.png";
import closeIcon from "../../assets/images/close.png";
import settingsIcon from "../../assets/images/gear.png";
import thoughtCloudImg from "../../assets/images/thought-cloud.png";
import PauseScreen from "./PauseScreen";
import OpponentLeft from "./OpponentLeft";
import {useUIContext} from "../../providers/UIProvider";
import QuitModal from "../QuitModal";
import DotFlashing from "../../components/DotFlashing";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const Playground = () => {
  const [ref] = useScale();
  const {playSound} = useAudioContext();
  const {
    me, opponent,
    pickChoice, countdown, winner, connected,
    isPrivate, pause, paused,
    ableToPick, visibleAlone, leave,
    roundEnded,
  } = useGameContext();
  const opponentShakeStyle = React.useMemo(() => {
    if (roundEnded) return style.OpponentShake;
    return null;
  }, [roundEnded]);
  const meShakeStyle = React.useMemo(() => {
    if (roundEnded) return style.MeShake;
    return null;
  }, [roundEnded]);
  const [mouseOn, setMouseOn] = React.useState(null);
  React.useEffect(() => {
    setMouseOn(null);
  }, [roundEnded]);

  const [choice, setChoice] = React.useState(me?.choice);
  const [visibleCountdown, setVisibleCountdown] = React.useState(false);
  const {setVisibleGear} = useUIContext();
  const {t} = useTranslation();

  React.useEffect(() => {
    if (ableToPick)
      document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    }
  }, [ableToPick]);

  const handleKeydown = e => {
    if (e.keyCode === 37) {
      _setChoice(CHOICE_ROCK);
    } else if (e.keyCode === 38) {
      _setChoice(CHOICE_PAPER);
    } else if (e.keyCode === 39) {
      _setChoice(CHOICE_SCISSORS);
    }
  }

  const [visibleResult, _setVisibleResult] = React.useState(false);
  const visibleResultRef = React.useRef(visibleResult);
  const setVisibleResult = v => {
    _setVisibleResult(v);
    visibleResultRef.current = v;
  }
  const [opponentPickStyle, setOpponentPickStyle] = React.useState(null);
  const [mePickStyle, setMePickStyle] = React.useState(null);
  const amIWinner = React.useMemo(() => winner?.id === me?.id, [me?.id, winner?.id]);
  const isOpponentWinner = React.useMemo(() => winner?.id === opponent?.id, [opponent?.id, winner?.id]);

  // winner growing, loser move back styling
  React.useEffect(() => {
    if (visibleResult) {
      if (isOpponentWinner) {
        setTimeout(() => {
          setOpponentPickStyle(style.Grow);
          setMePickStyle(style.MoveBack);
        }, 300);
      } else if (amIWinner) {
        setTimeout(() => {
          setMePickStyle(style.Grow);
          setOpponentPickStyle(style.MoveBack);
        }, 300);
      } else {
        setTimeout(() => {
          setMePickStyle(style.MoveHalfBack);
          setOpponentPickStyle(style.MoveHalfBack);
        }, 300);
      }
    }
  }, [visibleResult, amIWinner, isOpponentWinner]);
  const [visibleWinMessage, setVisibleWinMessage] = React.useState(false);
  React.useEffect(() => {
    if (visibleResult) {
      setTimeout(() => {
        setVisibleWinMessage(true);
      }, 1300);
    } else {
      setVisibleWinMessage(false);
    }
  }, [visibleResult]);

  const [meWins, setMeWins] = React.useState(0);
  // round winning mark updates after showing winning message
  React.useEffect(() => {
    if (visibleResult)
      setTimeout(() => {
        setMeWins(me?.wins);
      }, 1300);
  }, [visibleResult, me?.wins]);
  // round winning mark updates after showing winning message
  const [opponentWins, setOpponentWins] = React.useState(0);
  React.useEffect(() => {
    if (visibleResult)
      setTimeout(() => {
        setOpponentWins(opponent?.wins);
      }, 1300);
  }, [visibleResult, opponent?.wins]);

  const [shakeCnt, _setShakeCnt] = React.useState(0);
  const shakeCntRef = React.useRef(shakeCnt);
  const setShakeCnt = v => {
    _setShakeCnt(v);
    shakeCntRef.current = v;
  }
  const [shakeLabel, setShakeLabel] = React.useState(null);
  React.useEffect(() => {
    const label = {
      1: `${t('Rock')}!`,
      2: `${t('Paper')}!`,
      3: `${t('Scissors')}!`,
      4: `${t('Shoot')}`,
    };
    if ([1, 2, 3, 4].includes(shakeCnt)) {
      setTimeout(() => {
        setShakeLabel(label[shakeCnt]);
        if ([1, 2, 3].includes(shakeCnt)) {
          playSound("fist-thrust");
        } else {
          playSound("reveal");
        }
      }, 600);
    } else {
      setTimeout(() => {
        setShakeLabel(null);
      }, 600);
    }
  }, [shakeCnt]);

  React.useEffect(() => {
    if (!roundEnded) {
      setShakeCnt(0);
      setVisibleResult(false);
      setOpponentPickStyle(null);
      setMePickStyle(null);
      setVisibleWinMessage(false);
    }

    if (roundEnded) {
      setVisibleCountdown(false);
    } else {
      if (countdown === 0) {
        setTimeout(() => {
          setVisibleCountdown(false);
        }, 1000);
      } else if (countdown >= 5 || countdown === null) {
        setVisibleCountdown(false);
      } else {
        setVisibleCountdown(true);
      }
    }
  }, [countdown, roundEnded]);

  React.useEffect(() => {
    if (connected)
      pickChoice(choice);
  }, [choice, connected]);

  React.useEffect(() => {
    setChoice(me?.choice);
  }, [me?.choice]);

  const _setChoice = choice => {
    if (!ableToPick) return;
    playSound('select');
    setChoice(choice);
  }

  const paperPicked = React.useMemo(() => CHOICE_MAPPING[me?.choice] === 1, [me?.choice, roundEnded]);
  const rockPicked = React.useMemo(() => CHOICE_MAPPING[me?.choice] === 2, [me?.choice, roundEnded]);
  const scissorsPicked = React.useMemo(() => CHOICE_MAPPING[me?.choice] === 3, [me?.choice, roundEnded]);
  const visibleAllPad = React.useMemo(() => !(paperPicked || rockPicked || scissorsPicked), [paperPicked, rockPicked, scissorsPicked]);

  const [visibleCloseModal, setVisibleCloseModal] = React.useState(false);
  const handleQuit = () => {
    leave({forced: true, playNew: false, privateGame: false});
  }

  const opponentPicked = React.useMemo(() => CHOICES.includes(opponent?.choice), [opponent?.choice]);
  const mePicked = React.useMemo(() => CHOICES.includes(me?.choice), [me?.choice]);

  const fistRef = React.useRef(null);

  const animationStart = React.useCallback(() => {
    setShakeCnt(shakeCntRef.current + 1);
  }, [setShakeCnt]);
  const animationEnd = React.useCallback(() => {
    setVisibleResult(true);
    setShakeCnt(shakeCntRef.current + 1);
  }, [setShakeCnt]);
  const animationIterate = React.useCallback(() => {
    setShakeCnt(shakeCntRef.current + 1);
  }, [setShakeCnt]);

  React.useEffect(() => {
    if (fistRef.current) {
      fistRef.current.addEventListener('animationstart', animationStart)
      fistRef.current.addEventListener('animationend', animationEnd);
      fistRef.current.addEventListener('animationiteration', animationIterate);
    }

    return () => {
      if (fistRef.current) {
        fistRef.current.removeEventListener('animationstart', animationStart);
        fistRef.current.removeEventListener('animationend', animationEnd);
        fistRef.current.removeEventListener('animationiteration', animationIterate);
      }
    }
  }, [fistRef.current, animationStart, animationEnd, animationIterate]);

  const winnerFull = React.useMemo(() => {
    if (amIWinner) return me;
    else if (isOpponentWinner) return opponent;
    return null;
  }, [isOpponentWinner, amIWinner, opponent?.choice, me?.choice]);

  const winMessage = React.useMemo(() => {
    return winnerFull?.choice ? WIN_MESSAGE[winnerFull?.choice] : t('Draw');
  }, [winnerFull?.choice]);

  React.useEffect(() => {
    if (visibleWinMessage) {
      if (amIWinner) {
        playSound("round-win");
      } else if (isOpponentWinner) {
        playSound("round-loss");
      } else {
        playSound("draw");
      }
    }
  }, [visibleWinMessage, amIWinner, isOpponentWinner]);

  const PlayerAvatar = (
    {
      player,
    }) => {
    const playerFaceDirectory = React.useMemo(() => faceDirectoryMap[parseInt(player?.breed) - 1], [player?.breed]);

    return (
      <>
        {
          Boolean(player?.breed) ?
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/breed/${player.breed}.png`} alt="breed icon"/> :
            <img
              className={clsx(style.PreviewImg)}
              src={`/avatar/breed/2.png`} alt="breed icon"/>
        }
        {
          Boolean(player?.hat) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/hat/${player.hat}.png`} alt="hat icon"/>
        }
        {
          Boolean(playerFaceDirectory) && Boolean(player?.face) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/face/${playerFaceDirectory}/${player.face}.png`} alt="face icon"/>
        }
        {
          Boolean(player?.necktie) &&
          <img
            className={clsx(style.PreviewImg)}
            src={`/avatar/accessory/${player.necktie}.png`} alt="accessory icon"/>
        }
      </>
    )
  }

  React.useEffect(() => {
    if (visibleCountdown) {
      playSound('countdown');
    }
  },[visibleCountdown]);

  const opponentBackPatternColor = React.useMemo(() => {
    if ([7, 8].includes(parseInt(opponent?.pattern ?? "0"))) {
      return "back";
    } else {
      return opponent?.patternColor;
    }
  }, [opponent?.pattern, opponent?.patternColor]);

  const meBackPatternColor = React.useMemo(() => {
    if ([7, 8].includes(parseInt(me?.pattern ?? "0"))) {
      return "back";
    } else {
      return me?.patternColor;
    }
  }, [me?.pattern, me?.patternColor]);

  return (
    <div ref={ref} className={clsx(style.Wrapper)}>
      {paused && <PauseScreen/>}

      {visibleAlone && <OpponentLeft/>}

      <QuitModal visible={visibleCloseModal} onOk={() => {
        playSound('click');
        handleQuit();
      }} onCancel={() => {
        playSound('click');
        setVisibleCloseModal(false);
      }}/>

      <div className={clsx(style.InnerWrapper)}>
        <div
          className={clsx(style.Top)}
          style={{backgroundColor: opponent?.color}}
        >
          <div className={clsx(style.Toolbar)}>
            <div/>

            <div className={clsx(style.SettingsWrapper)}>
              <img src={settingsIcon} alt="settings icon" onClick={() => {
                playSound('click');
                setVisibleGear(true);
              }}/>
              {
                isPrivate && !paused &&
                <img
                  src={pauseIcon}
                  alt="pause icon"
                  onClick={() => {
                    playSound('click');
                    pause();
                  }}
                />
              }
              {
                !isPrivate &&
                <img
                  src={closeIcon}
                  alt="pause icon"
                  onClick={() => {
                    playSound('click');
                    setVisibleCloseModal(true);
                  }}
                />
              }
            </div>
          </div>
          {
            roundEnded ? (
              visibleResult ?
                <div className={clsx(style.OpponentPick, opponentPickStyle)}>
                  {
                    Boolean(opponent?.mitt) &&
                    <img
                      className={clsx(style.PreviewImg)}
                      src={`/paw/mitts/${opponent?.mitt}/${opponent?.left ? LOSE_MAPPING_V2[me?.choice] : (CHOICE_MAPPING[opponent?.choice] ?? 1)}.png`}
                      alt="paw mitts"
                    />
                  }
                  <img
                    className={clsx(style.PreviewImg)}
                    src={`/paw/toe/${opponent?.toe ?? 1}/${opponent?.left ? LOSE_MAPPING_V2[me?.choice] : (CHOICE_MAPPING[opponent?.choice] ?? 1)}.png`}
                    alt="paw toe"
                  />
                  {
                    Boolean(opponent?.pattern) &&
                    Boolean(opponent?.patternColor) &&
                    <img
                      className={clsx(style.PreviewImg)}
                      src={`/paw/patterns/${opponent?.pattern}/${opponent?.patternColor}.png`}
                      alt="pattern"
                    />
                  }
                </div> :
                <div className={clsx(style.OpponentFistPreview, opponentShakeStyle)}>
                  <img src={`/paw/mitts/${opponent?.mitt}/clash/fist.png`} alt="opponent fist"/>
                  {
                    Boolean(opponent?.pattern) &&
                    Boolean(opponentBackPatternColor) &&
                    <img
                      src={`/paw/patterns/${opponent?.pattern}/${opponentBackPatternColor}.png`}
                      alt="pattern"
                    />
                  }
                </div>
            ) : (
              opponentPicked ?
                <div className={clsx(style.OpponentFistPreview)}>
                  <img src={`/paw/mitts/${opponent?.mitt}/clash/fist.png`} alt="opponent fist"/>
                  {
                    Boolean(opponent?.pattern) &&
                    Boolean(opponentBackPatternColor) &&
                    <img
                      src={`/paw/patterns/${opponent?.pattern}/${opponentBackPatternColor}.png`}
                      alt="pattern"
                    />
                  }
                </div>
                :
                <div className={clsx(style.AvatarPreview)}>
                  <PlayerAvatar player={opponent}/>
                  <img className={clsx(style.ThoughtCloud)} src={thoughtCloudImg} alt="thought cloud"/>
                  <DotFlashing/>
                </div>
            )
          }

          <div className={clsx(style.Information)}>
            <div className={clsx(style.Name)}>
              <span>{opponent?.name}</span>
            </div>
            <div className={clsx(style.History)}>
              <div className={clsx(style.MarkWrapper)}>
                <div className={clsx(style.Mark, opponentWins >= 1 ? style.Fill : null)}/>
                <div className={clsx(style.Mark, opponentWins >= 2 ? style.Fill : null)}/>
              </div>
            </div>
          </div>
        </div>
        {
          visibleCountdown &&
          <div className={clsx(style.CounterWrapper)}>
            <span className={clsx(style.Counter)}>:0{countdown}</span>
          </div>
        }
        {
          Boolean(shakeLabel) &&
          <div className={clsx(style.ShakeLabelWrapper)}>
            <span>{shakeLabel}</span>
          </div>
        }
        {
          Boolean(visibleWinMessage) && Boolean(winMessage) &&
          <div
            className={clsx(style.WinMessage, amIWinner ? style.Me : isOpponentWinner ? style.Opponent : style.Draw)}>
            <span>{winMessage}</span>
          </div>
        }
        <div
          className={clsx(style.Bottom)}
          style={{backgroundColor: me?.color}}
        >
          <div className={clsx(style.Information)}>
            <div className={clsx(style.History)}>
              <div className={clsx(style.MarkWrapper)}>
                <div className={clsx(style.Mark, meWins >= 1 ? style.Fill : null)}/>
                <div className={clsx(style.Mark, meWins >= 2 ? style.Fill : null)}/>
              </div>
            </div>
            <div className={clsx(style.Name)}>
              <span>{me?.name}</span>
            </div>
          </div>

          {
            roundEnded ? (
                visibleResult ?
                  <div className={clsx(style.MyPick, mePickStyle)}>
                    {
                      Boolean(me?.mitt) &&
                      <img
                        className={clsx(style.PreviewImg)}
                        src={`/paw/mitts/${me?.mitt}/${CHOICE_MAPPING[me?.choice] ?? 1}.png`}
                        alt="paw mitts"
                      />
                    }
                    <img
                      className={clsx(style.PreviewImg)}
                      src={`/paw/toe/${me?.toe ?? 1}/${CHOICE_MAPPING[me?.choice] ?? 1}.png`}
                      alt="paw toe"
                    />
                    {
                      Boolean(me?.pattern) &&
                      Boolean(me?.patternColor) &&
                      <img
                        className={clsx(style.PreviewImg)}
                        src={`/paw/patterns/${me?.pattern}/${me?.patternColor}.png`}
                        alt="pattern"
                      />
                    }
                  </div> :
                  <div className={clsx(style.MeFistPreview, style.AtBottom, meShakeStyle)}>
                    <img src={`/paw/mitts/${me?.mitt}/clash/fist.png`} alt="opponent fist" ref={fistRef}/>
                    {
                      Boolean(me?.pattern) &&
                      Boolean(meBackPatternColor) &&
                      <img
                        src={`/paw/patterns/${me?.pattern}/${meBackPatternColor}.png`}
                        alt="pattern"
                      />
                    }
                  </div>
              )
              : (
                mePicked ?
                  <div className={clsx(style.MeFistPreview)}>
                    <img src={`/paw/mitts/${me?.mitt}/clash/fist.png`} alt="opponent fist"/>
                    {
                      Boolean(me?.pattern) &&
                      Boolean(meBackPatternColor) &&
                      <img
                        src={`/paw/patterns/${me?.pattern}/${meBackPatternColor}.png`}
                        alt="pattern"
                      />
                    }
                  </div>
                  :
                  <>
                    <div className={clsx(style.AvatarPreview)}>
                      <PlayerAvatar player={me}/>
                    </div>
                    <div className={clsx(style.Notification)}>
                      <span>{t("Pick Your Paw")}</span>
                    </div>
                  </>
              )
          }

          {
            !roundEnded &&
            <div
              className={clsx(style.Footer)}
              style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/grass-bg.png'})`}}
            >
              <div className={clsx(style.FooterInner)}>
                {
                  (visibleAllPad || rockPicked) &&
                  <div
                    className={clsx(style.Pad)}
                    onMouseEnter={() => setMouseOn("rock")}
                    onMouseLeave={() => setMouseOn(null)}
                    style={{left: rockPicked ? '145px' : `10px`}}
                  >
                    <div
                      className={clsx(style.PawPreview, rockPicked ? style.Picked : mouseOn === 'rock' ? style.Hovered : null)}
                      onClick={() => _setChoice(CHOICE_ROCK)}
                    >
                      {
                        Boolean(me?.mitt) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          style={{background: me?.color}}
                          src={`/paw/mitts/${me?.mitt}/2.png`} alt="paw mitts"/>
                      }
                      <img
                        className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                        src={`/paw/toe/${me?.toe ?? 1}/2.png`} alt="paw toe"/>
                      {
                        Boolean(me?.pattern) &&
                        Boolean(me?.patternColor) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          src={`/paw/patterns/${me?.pattern}/${me?.patternColor}.png`}
                          alt="pattern"
                        />
                      }
                    </div>
                    <span className={clsx(rockPicked || mouseOn === 'rock' ? style.Yellow : null, style.ChoiceLabel)}>{t("Rock")}</span>
                  </div>
                }

                {
                  (visibleAllPad || paperPicked) &&
                  <div
                    className={clsx(style.Pad)}
                    onMouseEnter={() => setMouseOn("paper")}
                    onMouseLeave={() => setMouseOn(null)}
                    style={{left: paperPicked ? '145px' : `145px`}}
                  >
                    <div
                      className={clsx(style.PawPreview, paperPicked ? style.Picked : mouseOn === 'paper' ? style.Hovered : null)}
                      onClick={() => _setChoice(CHOICE_PAPER)}
                    >
                      {
                        Boolean(me?.mitt) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          style={{background: me?.color}}
                          src={`/paw/mitts/${me?.mitt}/1.png`} alt="paw mitts"/>
                      }
                      <img
                        className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                        src={`/paw/toe/${me?.toe ?? 1}/1.png`} alt="paw toe"/>
                      {
                        Boolean(me?.pattern) &&
                        Boolean(me?.patternColor) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          src={`/paw/patterns/${me?.pattern}/${me?.patternColor}.png`}
                          alt="pattern"
                        />
                      }
                    </div>
                    <span className={clsx(paperPicked || mouseOn === 'paper' ? style.Yellow : null, style.ChoiceLabel)}>{t("Paper")}</span>
                  </div>
                }

                {
                  (visibleAllPad || scissorsPicked) &&
                  <div
                    className={clsx(style.Pad)}
                    onMouseEnter={() => setMouseOn("scissors")}
                    onMouseLeave={() => setMouseOn(null)}
                    style={{left: scissorsPicked ? '145px' : `270px`}}
                  >
                    <div
                      className={clsx(style.PawPreview, scissorsPicked ? style.Picked : mouseOn === 'scissors' ? style.Hovered : null)}
                      onClick={() => _setChoice(CHOICE_SCISSORS)}
                    >
                      {
                        Boolean(me?.mitt) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          style={{background: me?.color}}
                          src={`/paw/mitts/${me?.mitt}/3.png`} alt="paw mitts"/>
                      }
                      <img
                        className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                        src={`/paw/toe/${me?.toe ?? 1}/3.png`} alt="paw toe"/>
                      {
                        Boolean(me?.pattern) &&
                        Boolean(me?.patternColor) &&
                        <img
                          className={clsx(style.PreviewImg, !ableToPick ? style.CursorDefault : null)}
                          src={`/paw/patterns/${me?.pattern}/${me?.patternColor}.png`}
                          alt="pattern"
                        />
                      }
                    </div>
                    <span className={clsx(scissorsPicked || mouseOn === 'scissors' ? style.Yellow : null, style.ChoiceLabel)}>{t("Scissors")}</span>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Playground;
