import * as React from "react";
import {useGameContext} from "../../providers/GameProvider";
import PublicLobby from "./PublicLobby";
import PrivateLobby from "./PrivateLobby";

const Lobby = () => {
  const {isPrivate} = useGameContext();

  return (
    isPrivate ?
      <PrivateLobby/> :
      <PublicLobby/>
  )
}

export default Lobby;
