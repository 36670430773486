import * as React from "react";

const ToeBeans = (
  {
    fill = '#fff',
  }) => {
  return (
    <svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.9516 46.9539C33.4485 46.3762 31.0141 45.2347 28.5247 44.5883C23.5873 43.2955 20.5477 47.8204 14.9776 46.8714C12.974 46.6352 11.145 45.6169 9.88885 44.0382C7.86709 40.9986 9.68252 36.7351 10.1639 35.6073C12.8458 29.2807 20.7953 24.1644 29.1574 24.6045C33.8152 24.9216 38.221 26.8355 41.6318 30.0234C43.799 31.9572 45.4649 34.3876 46.4867 37.1064C47.1679 38.9291 47.1679 40.9364 46.4867 42.7591C46.003 43.8112 45.2838 44.738 44.3848 45.4677C43.4857 46.1975 42.4308 46.7107 41.3017 46.9676C39.5436 47.3972 37.7074 47.3924 35.9516 46.9539Z"
        fill={fill}/>
      <path
        d="M42.5861 11.4847C43.3081 5.89508 40.7484 0.957558 36.8688 0.456439C32.9891 -0.0446814 29.2588 4.08037 28.5368 9.67003C27.8148 15.2597 30.3745 20.1973 34.2542 20.6984C38.1338 21.1995 41.8641 17.0744 42.5861 11.4847Z"
        fill={fill}/>
      <path
        d="M20.1711 20.2306C24.0396 19.6497 26.4971 14.6605 25.6601 9.08688C24.8232 3.51328 21.0087 -0.534101 17.1402 0.0468067C13.2717 0.627715 10.8142 5.61693 11.6512 11.1905C12.4881 16.7641 16.3026 20.8115 20.1711 20.2306Z"
        fill={fill}/>
      <path
        d="M7.79236 31.3061C11.3312 30.7803 13.6335 26.5413 12.9347 21.838C12.236 17.1346 8.80084 13.748 5.26205 14.2737C1.72326 14.7994 -0.579079 19.0384 0.119647 23.7418C0.818374 28.4451 4.25357 31.8318 7.79236 31.3061Z"
        fill={fill}/>
      <path
        d="M54.8809 24.0542C55.5298 19.336 53.1886 15.117 49.6519 14.6306C46.1151 14.1442 42.7221 17.5748 42.0732 22.2929C41.4244 27.0111 43.7655 31.2302 47.3023 31.7166C50.839 32.2029 54.2321 28.7724 54.8809 24.0542Z"
        fill={fill}/>
    </svg>
  )
}

export default ToeBeans;
