import React, {useState, useContext} from "react";
import clickSound from "../assets/audios/button-click.mp3";
import buySound from "../assets/audios/buy.wav";
import claimSound from "../assets/audios/claim-daily-bonus.wav";
import countdownSound from "../assets/audios/countdown.wav";
import drawSound from "../assets/audios/draw.wav";
import fistThrustSound from "../assets/audios/fist-thrust-rock-paper-scissors.mp3";
import gameWinSound from "../assets/audios/game-win.wav";
import gameLossSound from "../assets/audios/game-loss.wav";
import matchFoundSound from "../assets/audios/match-found.wav";
import matchStartSound from "../assets/audios/match-start.wav";
import roundLossSound from "../assets/audios/round-loss.wav";
import roundWinSound from "../assets/audios/round-win.wav";
import selectSound from "../assets/audios/select-rock-paper-scissors.wav";
import revealSound from "../assets/audios/shoot-reveal-paws.wav";
import {getItem, setItem} from "../utils/Storage";

const AudioContext = React.createContext(null);

export const AudioProvider = ({children}) => {
  const [mute, setMute] = useState(getItem("mute") === "on");
  const [volume, setVolume] = useState(50);
  const audioClick = new Audio(clickSound);
  const audioBuy = new Audio(buySound);
  const audioClaim = new Audio(claimSound);
  const audioCountdown = new Audio(countdownSound);
  const audioDraw = new Audio(drawSound);
  const audioFistThrust = new Audio(fistThrustSound);
  const audioGameLoss = new Audio(gameLossSound);
  const audioMatchFound = new Audio(matchFoundSound);
  const audioMatchStart = new Audio(matchStartSound);
  const audioRoundLoss = new Audio(roundLossSound);
  const audioRoundWin = new Audio(roundWinSound);
  const audioSelect = new Audio(selectSound);
  const audioReveal = new Audio(revealSound);
  const audioGameWin = new Audio(gameWinSound);

  const play = (mode) => {
    if (!mute) {
      let audio = null;
      switch (mode) {
        case 'click':
          audio = audioClick;
          break;
        case 'buy':
          audio = audioBuy;
          break;
        case 'claim':
          audio = audioClaim;
          break;
        case 'countdown':
          audio = audioCountdown;
          break;
        case 'draw':
          audio = audioDraw;
          break;
        case 'fist-thrust':
          audio = audioFistThrust;
          break;
        case 'game-win':
          audio = audioGameWin;
          break;
        case 'game-loss':
          audio = audioGameLoss;
          break;
        case 'match-found':
          audio = audioMatchFound;
          break;
        case 'match-start':
          audio = audioMatchStart;
          break;
        case 'round-loss':
          audio = audioRoundLoss;
          break;
        case 'round-win':
          audio = audioRoundWin;
          break;
        case 'select':
          audio = audioSelect;
          break;
        case 'reveal':
          audio = audioReveal;
          break;
        default:
          console.log('audio not registered');
      }
      if (audio) {
        audio.volume = volume / 100;
        audio.play().catch(e => console.log(e));
      }
    }
  };

  const toggleMute = () => {
    if (!mute) play('click');
    setItem("mute", !mute ? "on" : "off");
    setMute(prevState => !prevState);
  };

  const providerValue = {
    mute,
    toggleMute,
    playSound: play,
    volume,
    setVolume,
  };

  return (
    <AudioContext.Provider value={providerValue}>
      {children}
    </AudioContext.Provider>
  )
};

export const useAudioContext = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudioContext must be used within AudioProvider");
  }
  return context;
};