import * as React from "react";
import {useGameContext} from "../../providers/GameProvider";

import EndScreen from "./EndScreen";
import Lobby from "../Lobby";
import Playground from "./Playground";
import PrivateEndScreen from "./PrivateEndScreen";
import {useUIContext} from "../../providers/UIProvider";

const PlayRoom = () => {
  const {
    visibleGameWinner,
    visiblePlayground,
    visibleLobby,
    visibleAlone,
    isPrivate,
  } = useGameContext();
  const {setVisiblePlayRoom} = useUIContext();
  React.useEffect(() => {
    setVisiblePlayRoom(visibleLobby || visiblePlayground || visibleAlone || visibleGameWinner);
    
    return () => {
      setVisiblePlayRoom(false);
    }
  }, [visibleLobby, visiblePlayground, visibleAlone, visibleGameWinner]);

  return (
    <React.Fragment>
      {visibleLobby && <Lobby/>}
      {(visiblePlayground || visibleAlone) && <Playground/>}
      {
        visibleGameWinner && (isPrivate ? <PrivateEndScreen/> : <EndScreen/>)
      }
    </React.Fragment>
  )
}

export default PlayRoom;
