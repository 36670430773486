import * as React from "react";

const NecktieIcon = (
  {
    fill='#fff',
  }) => {
  return (
    <svg width="55" height="25" viewBox="0 0 55 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.2263 0.00957685C44.9105 0.480005 38.835 3.69292 33.0597 6.82577C32.9749 6.18712 32.6615 5.60087 32.1774 5.17571C31.6934 4.75056 31.0716 4.51534 30.4273 4.51367H24.572C23.9278 4.51534 23.306 4.75056 22.8219 5.17571C22.3379 5.60087 22.0244 6.18712 21.9396 6.82577C16.1544 3.69292 10.0788 0.480005 3.77309 0.00957685C3.26906 -0.0060719 2.76722 0.0825148 2.29901 0.269785C1.83079 0.457055 1.40628 0.738978 1.05205 1.0979C0.697829 1.45682 0.421518 1.885 0.240432 2.35565C0.0593469 2.8263 -0.0226141 3.32926 -0.000326174 3.83305V20.7384C-0.0208498 21.2397 0.061246 21.7399 0.240936 22.2083C0.420626 22.6767 0.694113 23.1035 1.04463 23.4625C1.39515 23.8214 1.8153 24.105 2.27932 24.2958C2.74334 24.4865 3.24144 24.5805 3.74307 24.5719C10.1289 24.1415 16.2645 21.5692 22.0998 18.3763C22.3009 18.8662 22.6435 19.2851 23.0839 19.5793C23.5243 19.8734 24.0424 20.0296 24.572 20.0278H30.4273C30.9567 20.0283 31.4742 19.8716 31.9143 19.5776C32.3545 19.2835 32.6974 18.8654 32.8996 18.3763C38.7249 21.5291 44.9105 24.1415 51.2463 24.5719C51.7487 24.5819 52.2479 24.4889 52.7131 24.2988C53.1783 24.1086 53.5997 23.8253 53.9513 23.4662C54.3029 23.1071 54.5773 22.6798 54.7576 22.2107C54.9379 21.7416 55.0203 21.2406 54.9997 20.7384V3.83305C55.0205 3.32964 54.9375 2.82733 54.7559 2.35735C54.5742 1.88738 54.2979 1.4598 53.944 1.10119C53.59 0.742581 53.1661 0.460611 52.6986 0.272817C52.2311 0.0850228 51.7299 -0.00458105 51.2263 0.00957685Z" fill={fill}/>
    </svg>

  )
}

export default NecktieIcon;
