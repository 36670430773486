import * as React from "react";

const HatIcon = (
  {
    fill='#fff',
  }) => {
  return (
    <svg width="55" height="41" viewBox="0 0 55 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.83 32.9398H47.3V7.93981C47.2868 5.84093 46.443 3.83267 44.9533 2.35415C43.4635 0.875621 41.4489 0.0471261 39.35 0.0498112H15.2C13.0942 0.0498112 11.0746 0.886341 9.58557 2.37538C8.09653 3.86442 7.25999 5.884 7.25999 7.98981V32.9898H2.14C1.57244 32.9898 1.02811 33.2153 0.626785 33.6166C0.225457 34.0179 0 34.5623 0 35.1298V38.5098C0 39.0774 0.225457 39.6217 0.626785 40.023C1.02811 40.4243 1.57244 40.6498 2.14 40.6498H52.83C53.3976 40.6498 53.9419 40.4243 54.3432 40.023C54.7445 39.6217 54.97 39.0774 54.97 38.5098V35.0498C54.9621 34.4875 54.7332 33.9508 54.3327 33.556C53.9322 33.1611 53.3924 32.9398 52.83 32.9398Z" fill={fill}/>
    </svg>

  )
}

export default HatIcon;
