import * as React from "react";
import * as Colyseus from "colyseus.js";
import {socketUrl} from "../config";
import {useSettingContext} from "./SettingsProvider";
import {SIGNAL, GAME_MODE, GAME_STATUS} from "../constant";

const HomeContext = React.createContext(null);

export const HomeProvider = ({children}) => {
  const {
    publicName: name,
    historyPicks,
    winStreaks,
    breed,
    face,
    necktie,
    hat,
    mitt,
    toe,
    color,
    pattern,
    patternColor,
  } = useSettingContext();
  let client = new Colyseus.Client(socketUrl);

  const startGame = async (mode) => {
    try {
      const room = await client.joinOrCreate("rps", {
        mode,
        name,
        winStreaks: winStreaks,
        picks: historyPicks,
        breed,
        face,
        necktie,
        hat,
        pattern,
        patternColor,
        mitt,
        toe,
        color,
      });
      roomListener(room);
    } catch (e) {
      alert('Please make sure that your device has network connectivity');
    } finally {
      if (window.parent && window.parent.postMessage) {
        const eventValue = mode === 'public' ? 1 : mode === 'private' ? 2 : null;
        if (eventValue) {
          window.parent.postMessage(JSON.stringify({eventType: "gameEvent", key: "start", value: eventValue}), "*");
        }
      }
    }
  }

  const joinRoomById = async id => {
    return new Promise(async (resolve, reject) => {
      try {
        const room = await client.joinById(id, {
          name,
          winStreaks: winStreaks,
          picks: historyPicks,
          breed,
          face,
          necktie,
          hat,
          mitt,
          toe,
          pattern,
          patternColor,
          color,
        });
        resolve(room);
      } catch (e) {
        reject(e);
      }
    })
  }

  const roomListener = (room) => {
    const {id, sessionId} = room;
    room.onMessage("signal", data => {
      const {type} = data;

      switch (type) {
        case SIGNAL.WAIT:
          if ([GAME_MODE.PUBLIC, GAME_MODE.PRIVATE].includes(data.mode)) {
            window.location.href = (`/play-v2/${data.mode}/${id}/${sessionId}`);
          }
          break;
        default:
          console.log("type not registered", type);
      }
    });

    room.state.listen("status", (value) => {
      if (value !== GAME_STATUS.WAITING) { // if game was started
        if ([GAME_MODE.PUBLIC, GAME_MODE.PRIVATE].includes(room.state.mode)) {
          window.location.href = (`/play-v2/${room.state.mode}/${id}/${sessionId}`);
        }
      }
    });
  };

  const webAddress = () => {
    const url = window.location.href;
    const arr = url.split("/");
    return arr[0] + "//" + arr[2];
  };

  const providerValue = {
    client,
    startGame,
    joinRoomById,
    webAddress,
  };

  return (
    <HomeContext.Provider value={providerValue}>
      {children}
    </HomeContext.Provider>
  )
};

export const useHomeContext = () => {
  const context = React.useContext(HomeContext);
  if (!context) {
    throw new Error("useHomeContext must be used within HomeProvider");
  }
  return context;
};
