import React from "react";
import clsx from "clsx";
import style from "./PrivateGameMenu.module.scss";
import {getRoomIdFromCode} from "../../utils/http";
import {ButtonV2} from "./index";
import {useHomeContext} from "../../providers/HomeProvider";
import closeIcon from "../../assets/images/close.png";
import useScale from "../../hooks/useScale";
import { useUIContext } from "../../providers/UIProvider";
import {useAudioContext} from "../../providers/AudioProvider";
import {useTranslation} from "react-i18next";

const PrivateGameMenu = () => {
  const [value, setValue] = React.useState('');
  const {startGame, webAddress} = useHomeContext();
  const {setVisiblePrivateGameMenu} = useUIContext();
  const [ref] = useScale();
  const {playSound} = useAudioContext();
  const {t} = useTranslation();
  const [status, setStatus] = React.useState(null);

  const handleStart = () => {
    startGame('private');
  }

  const handleChange = e => {
    if (e.target.value?.length < 5) {
      setValue(e.target.value);
    }
  }

  const handleJoin = () => {
    const trimValue = value?.trim();
    if (trimValue?.length === 4) {
      if (/^\d+$/.test(trimValue)) {
        getRoomIdFromCode(trimValue)
          .then(v => {
            window.location.href = `${webAddress()}/play-v2/private/${v}/-1`;
          })
          // todo show error notification
          .catch(e => {
            console.error("get room id from code error", e);
            if (e.response.status === 400) {
              setStatus(t("Match doesn't exist!"));
            }
          });
      } else alert('Please enter valid digits');
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    handleJoin();
  }

  return (
    <div
      ref={ref}
      className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.Toolbar)}>
        <div/>
        <img
          src={closeIcon}
          alt="close icon"
          onClick={() => {
            playSound('click');
            setVisiblePrivateGameMenu(false);
          }}
          className={clsx(style.CloseIcon)}
        />
      </div>

      <div className={clsx(style.Title)}>
        <p>{t("Play A Friend")}</p>
      </div>

      <div className={clsx((style.Body))}>

        <div className={clsx(style.ButtonWrapper)}>
          <ButtonV2
            theme={'white'}
            label={t('Create Match')}
            onClick={handleStart}/>
        </div>
      </div>

      <div className={clsx((style.Body))}>
        <div className={clsx(style.Header)}>
          <p>
            {t("Join A Match")}
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={clsx(style.InputWrapper)}>
            <input value={value} type="number" onChange={handleChange}
                   placeholder={t("Enter Match Code")}/>
          </div>

          <div className={clsx(style.ButtonWrapper)}>
            <ButtonV2
              theme={'white'}
              label={t('Join')}
              onClick={handleJoin}
            />
          </div>
        </form>
      </div>

      {
        !!status &&
        <div className={clsx(style.Status)}>
          <span>{status}</span>
        </div>
      }
    </div>
  )
}

export default PrivateGameMenu;
