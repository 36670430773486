export const curses = [
  "3some",
  "3way",
  "4some",
  "abortion",
  "abuse",
  "addict",
  "adolescent",
  "adult",
  "anal",
  "anus",
  "arab",
  "areola",
  "ariola",
  "arouse",
  "arse",
  "asian",
  "asperg",
  "ass",
  "autis",
  "azn",
  "backdoor",
  "bang",
  "baptist",
  "barf",
  "bastard",
  "bbw",
  "bdsm",
  "beaner",
  "beastal",
  "beastial",
  "beat",
  "beatthemeat",
  "beaver",
  "beavr",
  "beer",
  "bestal",
  "bestial",
  "biatch",
  "bible",
  "bicurious",
  "bicuriouz",
  "bitch",
  "biteme",
  "blind",
  "bloody",
  "blow",
  "blowjob",
  "blueball",
  "boang",
  "bogan",
  "bohunk",
  "bollock",
  "bollok",
  "bomb",
  "bondage",
  "boner",
  "bong",
  "boob",
  "booger",
  "booty",
  "brazzers",
  "breast",
  "brothel",
  "bugger",
  "bukkake",
  "burn",
  "butch",
  "butt",
  "butter",
  "buttplug",
  "camalto",
  "cameltoe",
  "camgirl",
  "carpetmunch",
  "catholic",
  "4chan",
  "fourchan",
  "chaturbat",
  "cherry",
  "chick",
  "child",
  "china",
  "chinese",
  "chink",
  "chlam",
  "choad",
  "choak",
  "choat",
  "chode",
  "christ",
  "cigar",
  "cigs",
  "clamdi",
  "clit",
  "clogwog",
  "cocaine",
  "cock",
  "coital",
  "coitus",
  "coke",
  "commie",
  "commun",
  "condom",
  "cooch",
  "coolie",
  "cooly",
  "coon",
  "copulat",
  "cornhole",
  "cowgirl",
  "crabs",
  "crack",
  "crap",
  "creampie",
  "criminal",
  "crotch",
  "cuck",
  "cum",
  "cunt",
  "cybersex",
  "cyberslime",
  "dahmer",
  "damm",
  "damn",
  "darkie",
  "darky",
  "dck",
  "dead",
  "deapthroat",
  "deepthroat",
  "defecat",
  "dego",
  "democrat",
  "demon",
  "deustch",
  "devil",
  "devl",
  "dick",
  "diddle",
  "dildo",
  "dingleberry",
  "disease",
  "disturbed",
  "dix",
  "doggie",
  "doggy",
  "dominatr",
  "dong",
  "doodoo",
  "dookie",
  "dooky",
  "doom",
  "dope",
  "doublepen",
  "drag",
  "drug",
  "drunk",
  "dvl",
  "dxy",
  "dyke",
  "eatme",
  "ebony",
  "ecstacy",
  "ecstasy",
  "eggplant",
  "ejacu",
  "enema",
  "enemy",
  "entho",
  "erect",
  "erekt",
  "escort",
  "ethnic",
  "excrement",
  "execut",
  "explosion",
  "extacy",
  "extasy",
  "facesit",
  "facial",
  "fack",
  "faeces",
  "fag",
  "faith",
  "fanny",
  "fany",
  "fart",
  "faschi",
  "fasci",
  "fatso",
  "fck",
  "feaces",
  "feces",
  "feck",
  "felatio",
  "felch",
  "felching",
  "fellate",
  "fellatio",
  "feltch",
  "femdom",
  "fetish",
  "filipi",
  "finger",
  "fingr",
  "firing",
  "fist",
  "flange",
  "flanj",
  "flasher",
  "flatul",
  "flogging",
  "floo",
  "fnger",
  "fngr",
  "fok",
  "fondl",
  "footjob",
  "foresk",
  "fornicat",
  "foursome",
  "fourtwent",
  "fourtwnt",
  "fraud",
  "fubar",
  "fuck",
  "fudge",
  "fugly",
  "fuk",
  "funeral",
  "furries",
  "fuu",
  "gangbang",
  "gangbng",
  "geezer",
  "genital",
  "german",
  "ghetto",
  "girls",
  "givehead",
  "gloryhole",
  "gngbng",
  "god",
  "goldenshower",
  "gonorr",
  "gook",
  "gorilla",
  "greaseball",
  "gringo",
  "gross",
  "gun",
  "hamas",
  "handjob",
  "hardon",
  "harem",
  "hate",
  "hell",
  "hentai",
  "heroin",
  "herpes",
  "hetero",
  "hiddencam",
  "hijack",
  "hillbill",
  "hindoo",
  "hindu",
  "hitler",
  "hndjb",
  "hndjob",
  "hoe",
  "hole",
  "homicid",
  "homo",
  "honke",
  "hooker",
  "hooter",
  "horney",
  "horni",
  "horny",
  "hosejob",
  "hostage",
  "hottotrot",
  "housewife",
  "humiliat",
  "husky",
  "hussy",
  "hustler",
  "hymen",
  "hymie",
  "idiot",
  "illegal",
  "incest",
  "infidel",
  "interacial",
  "intercours",
  "interracial",
  "isis",
  "israel",
  "jacking",
  "jerk",
  "jesus",
  "jew",
  "jihad",
  "jimfish",
  "jism",
  "jiz",
  "joint",
  "jugg",
  "juggalo",
  "jugs",
  "junglebunn",
  "kaffir",
  "kaffre",
  "kafir",
  "kamasutra",
  "kike",
  "kill",
  "kink",
  "kkk",
  "knife",
  "knobend",
  "knockers",
  "kock",
  "kondum",
  "koon",
  "kotex",
  "krap",
  "krappy",
  "kunil",
  "kunnil",
  "kunnn",
  "kyke",
  "labia",
  "lactate",
  "lapdance",
  "latin",
  "lesb",
  "lez",
  "liberal",
  "libid",
  "licker",
  "lickme",
  "lickr",
  "lingerie",
  "liqr",
  "liquor",
  "lmao",
  "lmfao",
  "loaded",
  "lolita",
  "looser",
  "loser",
  "lovebone",
  "lovegoo",
  "lovegun",
  "lovejuice",
  "lovemuscle",
  "lovepistol",
  "loverocket",
  "lsd",
  "lubejob",
  "lucifer",
  "lynch",
  "macaca",
  "mackdaddy",
  "manhater",
  "manpaste",
  "marijuana",
  "maryjane",
  "mastabat",
  "masterbat",
  "mastrabat",
  "masturbat",
  "mattressprincess",
  "mckdaddy",
  "meatbeat",
  "meatrack",
  "meth",
  "mexicmgger",
  "mggor",
  "mideast",
  "milf",
  "minorities",
  "minority",
  "mockey",
  "mockie",
  "mocky",
  "mofo",
  "moky",
  "molest",
  "moneyshot",
  "monkey",
  "mooncricket",
  "mormon",
  "moron",
  "moslem",
  "moslim",
  "mothaf",
  "motherf",
  "muff",
  "mulato",
  "mulatto",
  "munch",
  "muncher",
  "munt",
  "murder",
  "muslem",
  "muslim",
  "nagger",
  "naggr",
  "naked",
  "narco",
  "nasty",
  "nazi",
  "necrophil",
  "negro",
  "nggr",
  "nigga",
  "nigger",
  "niggur",
  "niglet",
  "nignog",
  "nigr",
  "nigre",
  "nipple",
  "nittit",
  "nlgg",
  "nookie",
  "nooky",
  "nooner",
  "nude",
  "nuke",
  "nympho",
  "nymphette",
  "ontherag",
  "oral",
  "orgasim",
  "orgasm",
  "orgies",
  "orgy",
  "oriental",
  "orientl",
  "osama",
  "pakistan",
  "palestin",
  "pansies",
  "pansy",
  "payo",
  "pcketpool",
  "pearlnecklace",
  "pecker",
  "pedo",
  "peen",
  "peepshow",
  "pegging",
  "pendy",
  "pendejo",
  "penetrat",
  "penis",
  "penthouse",
  "period",
  "perln",
  "perv",
  "phillipi",
  "phuk",
  "pimp",
  "piss",
  "pistol",
  "pixie",
  "pixy",
  "playboy",
  "playgirl",
  "plmp",
  "pocketpool",
  "polack",
  "polock",
  "poontang",
  "poop",
  "poorwhite",
  "popimp",
  "porn",
  "poverty",
  "pregnat",
  "pregnant",
  "prick",
  "prln",
  "pronprincess",
  "prostat",
  "prostitut",
  "protestant",
  "pube",
  "pubic",
  "puckbunn",
  "pudboy",
  "puddboy",
  "puke",
  "punetang",
  "puntang",
  "pussy",
  "queef",
  "queer",
  "quickie",
  "racial",
  "racist",
  "raghead",
  "rape",
  "rapist",
  "rawdog",
  "rawdawg",
  "realityking",
  "rearend",
  "rearentry",
  "rectum",
  "redlight",
  "redneck",
  "reefer",
  "reestie",
  "refugee",
  "reich",
  "republican",
  "retard",
  "ribbed",
  "rigger",
  "rimjob",
  "rimming",
  "roundeye",
  "sadis",
  "sadom",
  "satan",
  "saudi",
  "scissoring",
  "schlong",
  "screw",
  "scrot",
  "scrotum",
  "scum",
  "seduce",
  "seducing",
  "semen",
  "seppuku",
  "servant",
  "sex",
  "shait",
  "shat",
  "shhhit",
  "shhit",
  "shit",
  "shoot",
  "siphilis",
  "sixsixsix",
  "sixtynine",
  "skank",
  "skinflute",
  "skum",
  "slanteye",
  "slaughter",
  "slave",
  "slay",
  "sleeze",
  "slideitin",
  "slimeball",
  "slimebucket",
  "slut",
  "smack",
  "smegma",
  "smoking",
  "smut",
  "snatch",
  "sniper",
  "sodom",
  "sonofa",
  "spank",
  "sperm",
  "spick",
  "spooge",
  "spreadeagle",
  "spunk",
  "stepbrother",
  "stepfather",
  "stepdaughter",
  "stepsister",
  "stiffy",
  "strapon",
  "strip",
  "stroke",
  "stroking",
  "stupid",
  "suck",
  "suicide",
  "swallow",
  "swastika",
  "swatstika",
  "swatztika",
  "swinger",
  "syphilis",
  "tampon",
  "teat",
  "terror",
  "testes",
  "testicl",
  "testicul",
  "thicklips",
  "thirdeye",
  "thirdleg",
  "threesom",
  "threesome",
  "threeway",
  "tinkle",
  "titjob",
  "titlick",
  "titlove",
  "tits",
  "tittie",
  "titty",
  "tnt",
  "toilet",
  "tongethrust",
  "tongue",
  "tosser",
  "towelhead",
  "trailertrash",
  "tramp",
  "trannie",
  "tranny",
  "transvest",
  "trojan",
  "tugjob",
  "turd",
  "turnon",
  "twat",
  "twerk",
  "twink",
  "upskirt",
  "urinar",
  "urinat",
  "urine",
  "usama",
  "uterus",
  "vagina",
  "vatican",
  "vibrat",
  "vietcong",
  "violen",
  "virgin",
  "vomit",
  "vulva",
  "wank",
  "waysted",
  "waysting",
  "weapon",
  "weed",
  "weenie",
  "weeny",
  "weewee",
  "welfare",
  "wetback",
  "wetspot",
  "whash",
  "whigger",
  "whiskey",
  "whisky",
  "whitechocolate",
  "whitetrash",
  "whitey",
  "whore",
  "wigger",
  "wine",
  "women",
  "wsuzzie",
  "wtf",
  "wuss",
  "yellowman",
  "yellowfever",
  "yomam",
  "yomom",
  "zigabo",
  "zipperhead",
];

export const numericCurses = [
  "69",
  "420",
  "666",
  "88",
];
