import * as React from "react";
import clsx from "clsx";
import style from "./Settings.module.scss";
import {useSettingContext} from "../../providers/SettingsProvider";
import treatIcon from "../../assets/images/treat.png";
import closeIcon from "../../assets/images/close.png";
import refreshIcon from "../../assets/images/refresh.png";
import BreedIcon from "../../components/Icons/Breed";
import FaceIcon from "../../components/Icons/Face";
import HatIcon from "../../components/Icons/Hat";
import NecktieIcon from "../../components/Icons/Necktie";
import {PRODUCTS} from "../../constant";
import AvatarCat from "../../components/Icons/AvatarCat";
import Paw from "../../components/Icons/Paw";
import ToeBeans from "../../components/Icons/ToeBeans";
import useScale from "../../hooks/useScale";
import ColorPicker from "./ColorPicker";
import Item from "./AssetItem";
import {useBaseContext} from "../../providers/BaseProvider";
import {useUIContext} from "../../providers/UIProvider";
import PatternIcon from "../../components/Icons/Pattern";
import PriceLabel from "./PriceLabel";
import {useAudioContext} from "../../providers/AudioProvider";

const Settings = () => {
  const {
    publicName: name,
    breedPreview, facePreview, necktiePreview, mitt, toePreview,
    patternColor, patternPreview,
    hatPreview,
    regenerateName,
    color,
    tab, setTab,
    faces,
    faceDirectory,
    footerTab, setFooterTab, getPrice, checkIfBought,
  } = useSettingContext();
  const {setVisibleSettings} = useUIContext();
  const {formattedCoin, coin} = useBaseContext();
  const avatarSelected = React.useMemo(() => tab === 'avatar', [tab]);
  const pawSelected = React.useMemo(() => tab === 'paw', [tab]);
  const breedSelected = React.useMemo(() => footerTab === 'breed', [footerTab]);
  const faceSelected = React.useMemo(() => footerTab === 'face', [footerTab]);
  const hatSelected = React.useMemo(() => footerTab === 'hat', [footerTab]);
  const necktieSelected = React.useMemo(() => footerTab === 'necktie', [footerTab]);
  const mittsSelected = React.useMemo(() => footerTab === 'mitts', [footerTab]);
  const toeSelected = React.useMemo(() => footerTab === 'toe', [footerTab]);
  const hats = Array.from(PRODUCTS.HAT.keys());
  const neckties = Array.from(PRODUCTS.ACCESSORY.keys());
  const toes = Array.from(PRODUCTS.TOES.keys());
  const patterns = Array.from(PRODUCTS.PATTERN.keys());
  const [ref] = useScale();
  const breeds = Array.from(PRODUCTS.BREED.keys());
  const [ableToBuy, setAbleToBuy] = React.useState(false);
  const [visibleBadge, setVisibleBadge] = React.useState(false);
  const [price, setPrice] = React.useState(null);
  const {playSound} = useAudioContext();

  React.useEffect(() => {
    let type = null;
    let index = null;
    switch (footerTab) {
      case 'breed':
        type = 'breed';
        index = breedPreview;
        break;
      case 'face':
        type = 'face';
        index = facePreview;
        break;
      case 'hat':
        type = 'hat';
        index = hatPreview;
        break;
      case 'necktie':
        type = 'necktie';
        index = necktiePreview;
        break;
      case 'mitts':
        type = 'pattern';
        index = patternPreview;
        break;
      case 'toe':
        type = 'toe';
        index = toePreview;
        break;
      default:
        console.error('type not registered');
    }

    if (type && index) {
      const price = getPrice({type, index});
      const bought = checkIfBought({type, index});
      const ableToBuy = coin >= price;
      const free = price === 0;
      const visibleBadge = !free && !bought;
      setVisibleBadge(visibleBadge);
      setAbleToBuy(ableToBuy);
      setPrice(price);
    } else {
      setVisibleBadge(false);
    }
  }, [footerTab, breedPreview, facePreview, toePreview, hatPreview, patternPreview, necktiePreview, coin]);

  React.useEffect(() => {
    if (avatarSelected) {
      if (!["breed", "face", "hat", "necktie"].includes(footerTab)) {
        setFooterTab("breed");
      }
    } else if (pawSelected && !["mitts", "toe"].includes(footerTab)) {
      setFooterTab("mitts");
    }
  }, [avatarSelected, pawSelected]);

  return (
    <div
      ref={ref}
      className={clsx(style.Wrapper)}
    >
      <div className={clsx(style.Toolbar)}>
        <div className={clsx(style.Left)}>
          <div className={clsx(style.CurrencyWrapper)}>
            <img src={treatIcon} className={clsx(style.TreatIcon)} alt="treat icon"/>

            <span>{formattedCoin}</span>
          </div>
        </div>

        <div className={clsx(style.SettingsWrapper)}>
          <img src={closeIcon} alt="close icon" onClick={() => {
            playSound('click');
            setVisibleSettings(false);
          }}/>
        </div>
      </div>

      <div className={clsx(style.Tab)}>
        <div
          className={clsx(style.AvatarWrapper, avatarSelected ? style.Selected : null)}
          onClick={() => {
            playSound('click');
            setTab('avatar');
          }}
        >
          <AvatarCat fill={avatarSelected ? 'white' : '#A96642'}/>
        </div>

        <div
          className={clsx(style.PawWrapper, pawSelected ? style.Selected : null)}
          onClick={() => {
            playSound('click');
            setTab('paw');
          }}
        >
          <Paw fill={pawSelected ? 'white' : '#A96642'}/>
        </div>
      </div>

      {
        avatarSelected ?
          <React.Fragment>
            <div className={clsx(style.Preview)}>
              {
                Boolean(breedPreview) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/breed/${breedPreview}.png`} alt="breed icon"
                  style={{background: color}}
                />
              }
              {
                Boolean(hatPreview) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/hat/${hatPreview}.png`} alt="hat icon"/>
              }
              {
                Boolean(breedPreview) &&
                Boolean(facePreview) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/face/${faceDirectory}/${facePreview}.png`} alt="face icon"/>
              }
              {
                Boolean(necktiePreview) &&
                <img
                  className={clsx(style.PreviewImg)}
                  src={`/avatar/accessory/${necktiePreview}.png`} alt="accessory icon"/>
              }
              <ColorPicker/>
              {visibleBadge && <PriceLabel disabled={!ableToBuy} price={price}/>}
            </div>
            <div className={clsx(style.NameWrapper)}>
              <div className={clsx(style.IconWrapper)}>
                <img
                  src={refreshIcon} className={clsx(style.RefreshIcon)}
                  alt="refresh icon"
                  onClick={() => {
                    playSound('click');
                    regenerateName();
                  }}
                />
              </div>
              <input type="text" value={name} className={clsx(style.NameInput)} readOnly={true}/>
            </div>
          </React.Fragment>
          : null
      }

      {
        pawSelected ?
          <div className={clsx(style.PawPreview)}>
            <div>
              <div className={clsx(style.PaperWrapper)}>
                {
                  Boolean(mitt) &&
                  <img
                    src={`/paw/mitts/${mitt}/1.png`}
                    style={{background: color}}
                    alt="paper mitts"
                  />
                }
                {
                  Boolean(toePreview) &&
                  <img
                    src={`/paw/toe/${toePreview}/1.png`}
                    alt="paper toe"
                  />
                }
                {
                  Boolean(patternPreview) &&
                  <img
                    src={`/paw/patterns/${patternPreview}/${patternColor}.png?p=1`}
                    alt="pattern"
                  />
                }
              </div>
            </div>

            <div>
              <div className={clsx(style.ScissorsWrapper)}>
                {
                  Boolean(mitt) &&
                  <img
                    src={`/paw/mitts/${mitt}/3.png`}
                    style={{background: color}}
                    alt="scissors mitts"
                  />
                }
                {
                  Boolean(toePreview) &&
                  <img
                    src={`/paw/toe/${toePreview}/3.png`}
                    alt="scissors toe"
                  />
                }
                {
                  Boolean(patternPreview) &&
                  <img
                    src={`/paw/patterns/${patternPreview}/${patternColor}.png`}
                    alt="pattern"
                  />
                }
              </div>

              <div className={clsx(style.RockWrapper)}>
                {
                  Boolean(mitt) &&
                  <img
                    src={`/paw/mitts/${mitt}/2.png`}
                    style={{background: color}}
                    alt="rock mitts"
                  />
                }
                {
                  Boolean(toePreview) &&
                  <img
                    src={`/paw/toe/${toePreview}/2.png`}
                    alt="rock toe"
                  />
                }
                {
                  Boolean(patternPreview) &&
                  <img
                    src={`/paw/patterns/${patternPreview}/${patternColor}.png`}
                    alt="pattern"
                  />
                }
              </div>
            </div>
            {visibleBadge && <PriceLabel onPaw={true} disabled={!ableToBuy} price={price}/>}
          </div> : null
      }

      <div className={clsx(style.FooterTab)}>
        <div className={clsx(style.TabHeader, avatarSelected ? style.Four : style.Two)}>
          {
            avatarSelected ? (
              <React.Fragment>
                <div
                  className={clsx(style.Item, breedSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('breed');
                  }}
                >
                  <BreedIcon fill={breedSelected ? '#fff' : '#A96642'}/>
                </div>
                <div
                  className={clsx(style.Item, faceSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('face');
                  }}
                >
                  <FaceIcon fill={faceSelected ? '#fff' : '#A96642'}/>
                </div>
                <div
                  className={clsx(style.Item, hatSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('hat');
                  }}
                >
                  <HatIcon fill={hatSelected ? '#fff' : '#A96642'}/>
                </div>
                <div
                  className={clsx(style.Item, necktieSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('necktie');
                  }}
                >
                  <NecktieIcon fill={necktieSelected ? '#fff' : '#A96642'}/>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className={clsx(style.Item, mittsSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('mitts');
                  }}
                >
                  <PatternIcon fill={mittsSelected ? '#fff' : '#A96642'}/>
                </div>
                <div
                  className={clsx(style.Item, toeSelected ? style.Selected : null)}
                  onClick={() => {
                    playSound('click');
                    setFooterTab('toe');
                  }}
                >
                  <ToeBeans fill={toeSelected ? '#fff' : '#A96642'}/>
                </div>
              </React.Fragment>
            )
          }
        </div>

        <div className={clsx(style.TabContent)}>
          {
            avatarSelected ? (
              <React.Fragment>
                {
                  breedSelected ?
                    breeds.map((it, index) => (
                      <Item
                        key={`breed-${it}`}
                        picked={breedPreview === it}
                        type={'breed'}
                        index={it}
                        orderIndex={index}
                        imageUrl={`/avatar/breed/${it}.png`}
                      />
                    )) : null
                }
                {
                  faceSelected ?
                    faces.map((_, index) => (
                      <Item
                        key={`face-${index}`}
                        picked={facePreview === index + 1}
                        type={'face'}
                        index={index + 1}
                        orderIndex={index}
                        imageUrl={`/avatar/face/${faceDirectory}/${index + 1}.png`}
                      />
                    )) : null
                }
                {
                  hatSelected ?
                    hats.map((it, index) => (
                      <Item
                        key={`hat-${it}`}
                        picked={hatPreview === it}
                        type={'hat'}
                        index={it}
                        orderIndex={index}
                        imageUrl={`/avatar/hat/thumbnails/${it}.png`}
                      />
                    )) : null
                }
                {
                  necktieSelected ?
                    neckties.map((it, index) => (
                      <Item
                        key={`necktie-${it}`}
                        picked={necktiePreview === it}
                        type={'necktie'}
                        index={it}
                        orderIndex={index}
                        imageUrl={`/avatar/accessory/thumbnails/${it}.png`}
                      />
                    )) : null
                }
              </React.Fragment>
            ) : (
              <React.Fragment>
                {
                  mittsSelected ?
                    patterns.map((it, index) => (
                      <Item
                        key={`pattern-${it}`}
                        picked={patternPreview === it}
                        type={'pattern'}
                        index={it}
                        orderIndex={index}
                        imageUrl={`/paw/patterns/thumbnails/${it}.png`}
                      />
                    )) : null
                }
                {
                  toeSelected ?
                    toes.map((it, index) => (
                      <Item
                        key={`toe-${it}`}
                        picked={toePreview === it}
                        index={it}
                        type={'toe'}
                        orderIndex={index}
                        imageUrl={`/paw/toe/thumbnails/${it}.png`}
                      />
                    )) : null
                }
              </React.Fragment>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Settings;
