import React from "react";
import App from "./App";
import {useUIContext} from "./providers/UIProvider";

const Wrapper = () => {
  const {style, visiblePlayRoom} = useUIContext();
  console.log(visiblePlayRoom ? '/play-bg.png' : '/full-bg.png');

  return (
    <div
      className={"outer-wrapper"}
      style={{backgroundImage: `url(${process.env.PUBLIC_URL + (visiblePlayRoom ? '/play-bg.png' : '/full-bg.png')})`}}
    >
      <div
        className={"inner-wrapper"}
        style={style}
      >
        <div className={'app-wrapper'}>
          <App/>
        </div>
      </div>
    </div>
  )
}

export default Wrapper;
