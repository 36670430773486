import * as React from "react";

const UIContext = React.createContext(null);

export const UIProvider = ({children}) => {
  const [loading, setLoading] = React.useState(false);
  const [visibleGear, setVisibleGear] = React.useState(false);
  const [visibleSettings, setVisibleSettings] = React.useState(false);
  const [visiblePrivateGameMenu, setVisiblePrivateGameMenu] = React.useState(false);
  const [visibleLobby, setVisibleLobby] = React.useState(false);
  const [visibleBonusModal, setVisibleBonusModal] = React.useState(false);
  const [visiblePrivateEnd, setVisiblePrivateEnd] = React.useState(false);
  const [visiblePlayRoom, setVisiblePlayRoom] = React.useState(false);

  const style = React.useMemo(() => {
    if (visibleSettings || visibleGear)
      return {backgroundColor: `#F1D7B6`};
    if (visibleLobby)
      return {backgroundColor: `rgba(241, 215, 182, 0.9)`};
    if (visibleBonusModal)
      return {backgroundColor: `rgba(19, 129, 155, 0.97)`};
    if (visiblePrivateGameMenu)
      return {backgroundColor: `rgba(245, 223, 190, 0.5)`};
    if (visiblePrivateEnd)
      return {backgroundColor: `rgba(245, 223, 190, 0.5)`};

    return null;
  }, [visibleGear, visibleSettings, visiblePrivateGameMenu, visibleLobby, visibleBonusModal, visiblePrivateEnd]);

  const providerValue = {
    loading,
    setLoading,
    visibleGear,
    setVisibleGear,
    visibleSettings,
    setVisibleSettings,
    visiblePrivateGameMenu,
    setVisiblePrivateGameMenu,
    setVisibleLobby,
    style,
    visibleBonusModal,
    setVisibleBonusModal,
    setVisiblePrivateEnd,
    visiblePlayRoom,
    setVisiblePlayRoom,
  };

  return (
    <UIContext.Provider value={providerValue}>
      {children}
    </UIContext.Provider>
  )
};

export const useUIContext = () => {
  const context = React.useContext(UIContext);
  if (!context) {
    throw new Error("useUIContext must be used within UIProvider");
  }
  return context;
};
